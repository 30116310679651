import React from "react";
import {
  Box,
  LinearProgress,
  Typography,
  Grid,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";
import {UseAppSelector} from "store/hooks";
import {selectPatientValues} from "../../patient/patientSlice";
import {selectDoctorValues} from "../../doctor/doctorSlice";

import {selectMedicineValues} from "../../med/medicine/medicineSlice";
import {selectDiagnosisValues} from "../../med/Diagnosis/diagnosisSlice";
import {selectSpecialMedType} from "../../med/AdditionalInformation/specialMedSlice";
import {selectMoreInformationValues} from "../../med/AdditionalInformation/forms/DefaultMedicineForm/defaultMedicineSlice";
import {selectPubmenStudy} from "store/features/newRequestSlice";
import {selectActivePraluentValues} from "../../med/AdditionalInformation/forms/PraluentForm/praluentSlice";
import {selectActiveBrukinsaValues} from "../../med/AdditionalInformation/forms/BrukinsaForm/BrukinsaSlice";

// import {selectBrukinsaCllValues} from "../../med/AdditionalInformation/forms/BrukinsaFormCll/BrukinsaCllSlice";
import {selectCalquenceValues} from "../../med/AdditionalInformation/forms/Calquence/CalquenceSlice";
import {selectEnhertuValues} from "../../med/AdditionalInformation/forms/EnhertuForm/EnhertuSlice";
import {selectIbranceValues} from "../../med/AdditionalInformation/forms/Ibrance/IbranceSlice";
import {selectSomatulineValues} from "../../med/AdditionalInformation/forms/Somatuline/SomatulineSlice";
import {selectImbruvicaValues} from "../../med/AdditionalInformation/forms/Imbruvica/ImbruvicaSlice";
import {selectImfinziValues} from "../../med/AdditionalInformation/forms/Imfinzi/ImfinziSlice";
import {selectImjudoValues} from "../../med/AdditionalInformation/forms/Imjudo/ImjudoSlice";
import {selectLynparzaValues} from "../../med/AdditionalInformation/forms/Lynparza/LynparzaSlice";
import {selectPraluentValues} from "../../med/AdditionalInformation/forms/PraluentForm/praluentSlice";
import {selectQuviviqValues} from "../../med/AdditionalInformation/forms/QuviviqForm/QuviviqSlice";
import {selectTagrissoValues} from "../../med/AdditionalInformation/forms/Tagrisso/TagrissoSlice";
import {selectCabometyxValues} from "../../med/AdditionalInformation/forms/Cabometyx/CabometyxSlice";
import {selectSpravatoValues} from "../../med/AdditionalInformation/forms/Spravato/SpravatoSlice";
import {selectTecvayliValues} from "../../med/AdditionalInformation/forms/Tecvayli/TecvayliSlice";
import {selectTalveyValues} from "../../med/AdditionalInformation/forms/Talvey/TalveySlice";
import {selectDarzalexValues} from "../../med/AdditionalInformation/forms/Darzalex/DarzalexSlice";
import {selectTukysaValues} from "../../med/AdditionalInformation/forms/Tukysa/TukysaSlice";

function CalculatedProgress() {
  let points = 0;
  let maximumFields = 0;
  const specialMed=UseAppSelector(selectSpecialMedType);
  const patientValues=UseAppSelector(selectPatientValues);
  const doctorValues=UseAppSelector(selectDoctorValues);
  const moreInforValues=UseAppSelector(selectMoreInformationValues);
  const medValues=UseAppSelector(selectMedicineValues);
  const diagnosisValues=UseAppSelector(selectDiagnosisValues);
  const pubmedStudy=UseAppSelector(selectPubmenStudy);
  const praluentValues=UseAppSelector(selectActivePraluentValues);
  const brukinsaValues=UseAppSelector(selectActiveBrukinsaValues);
  const calquenceValues=UseAppSelector(selectCalquenceValues);
  const enhertuValues=UseAppSelector(selectEnhertuValues);
  const ibranceValues=UseAppSelector(selectIbranceValues);
  const somatulineValues=UseAppSelector(selectSomatulineValues);
  const cabometyxValues=UseAppSelector(selectCabometyxValues);
  const imbruvicaValuses=UseAppSelector(selectImbruvicaValues);
  const imfinziValues=UseAppSelector(selectImfinziValues);
  const imjudoValues=UseAppSelector(selectImjudoValues);
  const lynparzaValues=UseAppSelector(selectLynparzaValues);
  const praluentActiveValues=UseAppSelector(selectPraluentValues);
  const quviviqValues=UseAppSelector(selectQuviviqValues);
  const tagrissoValues=UseAppSelector(selectTagrissoValues);
  const spravatoValues=UseAppSelector(selectSpravatoValues);
  const tecvayliValues=UseAppSelector(selectTecvayliValues);
  const talveyValues=UseAppSelector(selectTalveyValues);
  const darzalexValues=UseAppSelector(selectDarzalexValues);
  const tukysaValues=UseAppSelector(selectTukysaValues);

  if (patientValues.patient_agreement === true) points = points+50;
  maximumFields = maximumFields + 50;


  // 8 max points
  if (patientValues.patient_city) points++;
  if (
    patientValues.patient_gender == "0" ||
    patientValues.patient_gender == "1" ||
    patientValues.patient_gender == "2"
  ) points++;
  if (patientValues.patient_insurance?.DESCR1) points++;
  if (patientValues.patient_insurance_no) points++;
  if (patientValues.patient_lastname) points++;
  if (patientValues.patient_postalcode) points++;
  if (patientValues.patient_street) points++;
  if (patientValues.patient_surename) points++;
  maximumFields = maximumFields + 8;

  // 9 max points
  if (doctorValues.doctor_center_name) points++;
  if (doctorValues.doctor_city) points++;
  if (doctorValues.doctor_email_addr) points++;
  if (doctorValues.doctor_lastname) points++;
  if (doctorValues.doctor_postalcode) points++;
  if (doctorValues.doctor_specialty) points++;
  if (doctorValues.doctor_street) points++;
  if (doctorValues.doctor_surename) points++;
  maximumFields = maximumFields + 8;


  // 12 max points
  if (diagnosisValues.diagnosis) points++;
  if (diagnosisValues.remarks_diagnosis) points = points+10; // usefull
  if (medValues.required_drug) points++;
  maximumFields = maximumFields + 12;

  if (medValues.request_type!=="Inlabel") {
    // @ts-ignore:next-line
    if (pubmedStudy?.title) points = points+10; // usefull
    maximumFields = maximumFields + 10;
  }

  console.debug("specialMed", specialMed);
  if (!specialMed) {
    maximumFields = maximumFields + 40;
    if (moreInforValues.disease_prognosis) points = points+10; // usefull
    if (moreInforValues.patient_condition) points = points+10; // usefull
    if (moreInforValues.pretreatment) points = points+10; // usefull
    if (moreInforValues.therapy_alternatives) points = points+10; // usefull
  } else if (specialMed === "Praluent") {
    maximumFields = maximumFields + 40;
    if (praluentValues?.ersteverordnung === true ||
      praluentValues?.pcsk === true) points = points+40;
  } else if (specialMed === "Brukinsa") {
    maximumFields = maximumFields + 40;
    if (brukinsaValues?.hasTreatment === true||
      brukinsaValues?.wanlenstrom === true||
      brukinsaValues?.rituximab === true) points = points+40;
  } else if (specialMed === "BrukinsaCll") {
    maximumFields = maximumFields + 10;
    points = points+10;
  } else if (specialMed === "Calquence") {
    maximumFields = maximumFields + 10;
    points = points+10;
    if (calquenceValues?.ccl === true) {
      maximumFields = maximumFields+40;
      if (calquenceValues?.bonelliform?.dosis?.length > 0) points = points+10;
      if (calquenceValues?.bonelliform?.effect?.length > 0) points = points+10;
      if (calquenceValues?.bonelliform?.end?.length > 0) points = points+10;
      if (calquenceValues?.bonelliform?.start?.length > 0) points = points+10;
    } else {
      maximumFields = maximumFields + 10;
      points = points+10;
    }
  } else if (specialMed === "Enhertu") {
    maximumFields = maximumFields + 40;
    if (enhertuValues?.her2low === true) points = points+40;
    if (enhertuValues?.her2pos === true) {
      if (enhertuValues?.trastuzumabEmtansin === true ||
        enhertuValues?.trastuzumabTaxan === true) points = points+40;
    }
    if (enhertuValues?.other === true) {
      points = points+20;
      if (enhertuValues?.defaultForm?.patientCondition?.length > 0) points = points+5;
      if (enhertuValues?.defaultForm?.preTreatment?.length > 0) points = points+5;
      if (enhertuValues?.defaultForm?.prognosis?.length > 0) points = points+5;
      if (enhertuValues?.defaultForm?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+5;
    }
  } else if (specialMed === "Ibrance") {
    maximumFields = maximumFields + 40;
    if (ibranceValues?.aromataseCombi === true) points = points+20;
    if (ibranceValues?.fulvestrantCombi === true) points = points+20;
    if (ibranceValues?.fulvestrantCombi_1l === true || ibranceValues?.fulvestrantCombi_2l === true) points = points+20;
    if (ibranceValues?.aromataseCombi_Letrozol === true || ibranceValues?.aromataseCombi_2LOther === true) points = points+20;
    if (ibranceValues?.default === true) points = points+20;
    if (ibranceValues?.defaultForm?.patientCondition?.length > 0) points = points+5;
    if (ibranceValues?.defaultForm?.preTreatment?.length > 0) points = points+5;
    if (ibranceValues?.defaultForm?.prognosis?.length > 0) points = points+5;
    if (ibranceValues?.defaultForm?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+5;
  } else if (specialMed === "Imbruvica") {
    maximumFields = maximumFields + 40;
    if (imbruvicaValuses?.formCLL.variant > 0) points = points+40;
    else if (imbruvicaValuses?.formMCL.variant > 0) points = points+40;
    else if (imbruvicaValuses?.formMW.variant > 0) points = points+40;
  } else if (specialMed === "Cabometyx") {
    maximumFields = maximumFields + 40;
    if (cabometyxValues?.variant?.length > 0) points = points+40;
  } else if (specialMed === "Somatuline") {
    maximumFields = maximumFields + 40;
    if (somatulineValues?.variant?.length > 0) points = points+40;
  } else if (specialMed === "Imfinzi") {
    maximumFields = maximumFields + 40;
    if (imfinziValues?.topaz === true) {
      points = points+40;
    } else if (imfinziValues?.default === true) {
      if (imfinziValues?.defaultForm?.patientCondition?.length > 0) points = points+10;
      if (imfinziValues?.defaultForm?.preTreatment?.length > 0) points = points+10;
      if (imfinziValues?.defaultForm?.prognosis?.length > 0) points = points+10;
      if (imfinziValues?.defaultForm?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    } else if (imfinziValues?.himalaya === true) {
      points = 0;
    } else if (imfinziValues?.lungcarcinoma === true) {
      points = points+20;
      if (imfinziValues?.aegean === true) {
        points = points+20;
      } else if (imfinziValues?.caspian === true) {
        points = points+20;
      } else if (imfinziValues?.pacific === true) {
        points = points+20;
      } else if (imfinziValues?.poseidon === true) {
        points = points+20;
      } else if (imfinziValues?.adriatic === true) {
        points = points+20;
      }
    }
  } else if (specialMed === "Imjudo") {
    maximumFields = maximumFields + 40;
    if (imjudoValues?.default === true) {
      points = points+40;
    }
    if (imjudoValues?.himalaya === true) {
      points = points+40;
    }
    if (imjudoValues?.poseidon === true) {
      points = points+40;
    }
  } else if (specialMed === "Lynparza") {
    maximumFields = maximumFields + 40;
    if (lynparzaValues?.default === true) {
      if (lynparzaValues?.defaultForm?.patientCondition?.length > 0) points = points+10;
      if (lynparzaValues?.defaultForm?.preTreatment?.length > 0) points = points+10;
      if (lynparzaValues?.defaultForm?.prognosis?.length > 0) points = points+10;
      if (lynparzaValues?.defaultForm?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    }
    if (lynparzaValues?.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom === true) {
      points = points+40;
    }
    if (lynparzaValues?.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true) {
      points = points+20;
      if (lynparzaValues?.paola1BRCAMorHRDInCombinationWithBevacizumab === true) {
        points = points+20;
      } else if (lynparzaValues?.solo1BRCAm === true) {
        points = points+20;
      }
    }
  } else if (specialMed === "Praluent") {
    maximumFields = maximumFields + 40;
    if (
      praluentActiveValues?.ersteverordnung === true ||
      praluentActiveValues?.pcsk === true
    ) {
      points = points+40;
    }
  } else if (specialMed === "Tagrisso") {
    maximumFields = maximumFields + 40;
    if (tagrissoValues?.default === true) {
      points = points+10;
      if (tagrissoValues?.defaultForm?.patientCondition?.length > 0) points = points+10;
      if (tagrissoValues?.defaultForm?.preTreatment?.length > 0) points = points+10;
      if (tagrissoValues?.defaultForm?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    } else {
      if (tagrissoValues.variant === "FLAURA") points = points+40;
      if (tagrissoValues.variant === "FLAURA2") points = points+40;
      if (tagrissoValues.variant === "ADAURA") points = points+40;
    }
  } else if (specialMed === "Quviviq") {
    maximumFields = maximumFields + 20;
    if (
      quviviqValues?.justification?.patientRespondedToTherapy === true ||
      quviviqValues?.justification?.thereIsNoAlternativeDrug === true ||
      quviviqValues?.justification?.furtherJustification?.length > 0
    ) {
      points = points+20;
    }
  } else if (specialMed === "Spravato") {
    maximumFields = maximumFields + 40;
    if (spravatoValues?.firstAlternativeIndication === true) {
      points = points+40;
    } else if (spravatoValues?.firstSevereTreatmentResistantMajorDepressiveEpisode === true) {
      points = points+20;
      if (spravatoValues.thirdProgressReviewAfter4Weeks === true && spravatoValues.ninthCGICheckBox === false) {
        // nothing to do
      } else if (spravatoValues.thirdProgressReviewAfter4MonthsOfTherapy &&
        spravatoValues.eightCGISBeforeTreatmentWithSPRAVATO5 === false) {
        // nothing to do
      } else if (spravatoValues.thirdProgressReviewAfter7MonthsofTherapy &&
        spravatoValues.tenthCGICheckBox === false) {
        // nothing to do
      } else if (spravatoValues.thirdTherapyDurationOf10MonthsOrMore &&
        spravatoValues.eleventhCGICheckBox === false) {
        // nothing to do
      } else {
        points = points+20;
      }
    }
  } else if (specialMed === "Tecvayli") {
    maximumFields = maximumFields + 40;
    if (tecvayliValues?.isOtherIndication === true) {
      if (tecvayliValues?.patientCondition?.length > 0) points = points+10;
      if (tecvayliValues?.preTreatment?.length > 0) points = points+10;
      if (tecvayliValues?.prognosis?.length > 0) points = points+10;
      if (tecvayliValues?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    } else {
      if (tecvayliValues?.medications?.length >= 3) points = points+30;
      if (tecvayliValues?.medications?.length === 1) points = points+10;
      if (tecvayliValues?.medications?.length === 2) points = points+20;
      if (tecvayliValues?.comments?.length > 0) points = points+10;
    }
  } else if (specialMed === "Talvey") {
    maximumFields = maximumFields + 40;
    if (talveyValues?.isOtherIndication === true) {
      if (talveyValues?.patientCondition?.length > 0) points = points+10;
      if (talveyValues?.preTreatment?.length > 0) points = points+10;
      if (talveyValues?.prognosis?.length > 0) points = points+10;
      if (talveyValues?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    } else {
      if (talveyValues?.medications?.length >= 3) points = points+30;
      if (talveyValues?.medications?.length === 1) points = points+10;
      if (talveyValues?.medications?.length === 2) points = points+20;
      if (talveyValues?.comments?.length > 0) points = points+10;
    }
  } else if (specialMed === "Darzalex") {

  } else if (specialMed === "Tukysa") {
    maximumFields = maximumFields + 40;
    if (tukysaValues?.options_isother === true) {
      if (tukysaValues?.preTreatment?.length > 0) points = points+10;
      if (tukysaValues?.patientCondition?.length > 0) points = points+10;
      if (tukysaValues?.prognosis?.length > 0) points = points+10;
      if (tukysaValues?.reasonsAlternativeTherapiesFailed?.length > 0) points = points+10;
    }
    if (tukysaValues?.options_isher2 === true) {
      !tukysaValues.chk_trastuzumabDeruxtecan &&
      tukysaValues.chk_trastuzumab &&
      tukysaValues.chk_pertuzumab &&
      tukysaValues.chk_trastuzumabEmtansin && (points = points+40);
    }
    if (tukysaValues?.options_isher2 === true) {
      tukysaValues.chk_trastuzumabDeruxtecan &&
      (
        tukysaValues.chk_trastuzumab ||
        tukysaValues.chk_pertuzumab ||
        tukysaValues.chk_trastuzumabEmtansin) &&

      (points = points+40);
    }
  }

  if (points > maximumFields) points = maximumFields;
  return Math.round(points/maximumFields*100);
};

export const LinearProgressBar=()=>{
  const {t}=useTranslation();
  const progresValue=CalculatedProgress();
  let color;
  if (progresValue<20) {
    color="error";
  } else if (progresValue>=20 &&progresValue<=60) {
    color="warning";
  } else {
    color="success";
  }
  return (
    <>
      <Grid container item xs={12}>
        <Card sx={{padding: "12px", backgroundColor: "#e8f4fc"}} >
          <Grid item xs={12}>
            <Accordion
              sx={{width: "100%", backgroundColor: "#e8f4fc", boxShadow: "none"}}>
              <AccordionSummary
                sx={{padding: 0}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2d-content" id="panel2d-header">
                <Typography fontWeight='bold'> {t("wizard.progress.title")}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{padding: 0}}>
                <Typography whiteSpace='initial'>
                  {t("wizard.progress.information")}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Grid item xs={12}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%", mr: 1}}>
                  <LinearProgress variant="determinate" value={progresValue} color={color} />
                </Box>
                <Box sx={{minWidth: 35}}>
                  <Typography variant="body2" color="text.secondary">{`${progresValue}%`}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

        </Card>
      </Grid>
    </>
  );
};


