import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // Verabreichungsform
  "administrationAsPen",
  "administrationAsPreFilledSyringe",
  "administrationAsInfusionSolution",

  // Indikation
  "severePlaquePsoriasis",
  "psoriasisArthritis",
  "crohnsDisease",
  "ulcerativeColitis",
  "otherIndication",

  // "Plaque Psoriasis DLQI
  "plaquePsoriasis_dlqi_smaller10",
  "plaquePsoriasis_dlqi_larger10",
  "plaquePsoriasis_dlqi_pasi",
  "plaquePsoriasis_dlqi_bsa",
  "plaquePsoriasis_dlqi_pga",
  "plaquePsoriasis_dlqi_number",

  // "Plaque Psoriasis Manifestationen
  "plaquePsoriasis_befallVonGesicht",
  "plaquePsoriasis_befallDerKopfhaut",
  "plaquePsoriasis_befallDerGenitalien",
  "plaquePsoriasis_befallDerHaenderFingerUndOderNaegel",
  "plaquePsoriasis_starkerJuckreiz",
  "plaquePsoriasis_hartnaeckigePlaques",

  // "Plaque Psoriasis Vorhergehende Therapien
  "plaquePsoriasis_Phototherapie",
  "plaquePsoriasis_Ciclosporin",
  "plaquePsoriasis_Methotrexat",
  "plaquePsoriasis_Acitretin",
  "plaquePsoriasis_anderesBiologikum",
  "plaquePsoriasis_keineVortherapie",

  // "Plaque Psoriasis Grund an für die Anwendung von STELARA
  "plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg",
  "plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen",
  "plaquePsoriasis_begruendungDerKontraindikation",

  // Plaque Psoriasis Limitationen
  "plaquePsoriasis_dermatologySpecialist",
  "plaquePsoriasis_universityClinic",

  // Plaque Psoriasis Optionen
  "plaquePsoriasis_isChildAbove6AndLighterThan60kg",
  "plaquePsoriasis_isChildAbove6AndHeavierThan60kg",

  // Psoriasis Arthritis Limitationen
  "psoriasisArthritis_dermatologySpecialist",
  "psoriasisArthritis_universityClinic",

  // vorgängige Therapie
  "psoriasisArthritis_noPriorTherapy",
  "psoriasisArthritis_inadequateResponseToDMARD",

  // Kontraindikation
  "psoriasisArthritis_contraindication",

  // vorgesehen Therapie
  "psoriasisArthritis_monotherapy",
  "psoriasisArthritis_combinationTherapyWithMTX",
  "psoriasisArthritis_combinationTherapyWithOtherMedication",
  "psoriasisArthritis_medicationForCombinationTherapy",

  // Morbus Crohn
  // Morbus Crohn Limitationen
  "morbusCrohn_dermatologySpecialist",
  "morbusCrohn_universityClinic",

  // Behandlungsvorhaben
  "morbusCrohn_erstbehandlungMitStelara",
  "morbusCrohn_verkuerzungDesDosierungsintervalls",
  "morbusCrohn_weiterbehandlung",
  "morbusCrohn_BeschreibungVorbehandlung",
  "morbusCrohn_BeschreibungTherapie",

  // Morbus Crohn vorangegangene Therapie
  "morbusCrohn_behandlungMittelschweremBisSchweremAktiven",
  "morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",

  // Morbus Crohn Behandlungsvorhaben
  "morbusCrohn_monoTherapie",
  "morbusCrohn_kombinationstherapie",
  "morbusCrohn_kombinationstherapieMedikament",

  // Colitis Ulcerosa
  "colitisUlcerosa_dermatologySpecialist",
  "colitisUlcerosa_universityClinic",

  // Behandlungsvorhaben
  "colitisUlcerosa_erstbehandlungMitStelara",
  "colitisUlcerosa_verkuerzungDesDosierungsintervalls",
  "colitisUlcerosa_weiterbehandlung",

  // Colitis Ulcerosa vorangegangene Therapie
  "colitisUlcerosa_BeschreibungVorbehandlung",
  "colitisUlcerosa_BeschreibungTherapie",

  // vorangegangene Therapie
  "colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven",
  "colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",

  // Behandlungsvorhaben
  "colitisUlcerosa_monoTherapie",
  "colitisUlcerosa_kombinationstherapie",
  "colitisUlcerosa_kombinationstherapieMedikament",

  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",
];

export interface StelaraState {
  formData: {
    // Verabreichungsform
    administrationAsPen: boolean,
    administrationAsPreFilledSyringe: boolean,
    administrationAsInfusionSolution: boolean,

    // Indikation
    severePlaquePsoriasis: boolean,
    psoriasisArthritis: boolean,
    crohnsDisease: boolean,
    ulcerativeColitis: boolean,
    otherIndication: boolean,

    // "Plaque Psoriasis DLQI
    plaquePsoriasis_dlqi_smaller10: false,
    plaquePsoriasis_dlqi_larger10: false,
    plaquePsoriasis_dlqi_pasi: false,
    plaquePsoriasis_dlqi_bsa: false,
    plaquePsoriasis_dlqi_pga: false,
    plaquePsoriasis_dlqi_number: 0,

    // "Plaque Psoriasis Manifestationen
    plaquePsoriasis_befallVonGesicht: false,
    plaquePsoriasis_befallDerKopfhaut: false,
    plaquePsoriasis_befallDerGenitalien: false,
    plaquePsoriasis_befallDerHaenderFingerUndOderNaegel: false,
    plaquePsoriasis_starkerJuckreiz: false,
    plaquePsoriasis_hartnaeckigePlaques: false,

    // "Plaque Psoriasis Vorhergehende Therapien
    plaquePsoriasis_Phototherapie: false,
    plaquePsoriasis_Ciclosporin: false,
    plaquePsoriasis_Methotrexat: false,
    plaquePsoriasis_Acitretin: false,
    plaquePsoriasis_anderesBiologikum: false,
    plaquePsoriasis_keineVortherapie: false,

    // "Plaque Psoriasis Grund an für die Anwendung von STELARA
    plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg: false,
    plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen: false,
    plaquePsoriasis_begruendungDerKontraindikation: "",

    // Plaque Psoriasis Limitationen
    plaquePsoriasis_dermatologySpecialist: boolean,
    plaquePsoriasis_universityClinic: boolean,

    // Plaque Psoriasis Optionen
    plaquePsoriasis_isChildAbove6AndLighterThan60kg: boolean,
    plaquePsoriasis_isChildAbove6AndHeavierThan60kg: boolean,

    // Psoriasis Arthritis Limitationen
    psoriasisArthritis_dermatologySpecialist: boolean,
    psoriasisArthritis_universityClinic: boolean,

    // vorgängige Therapie
    psoriasisArthritis_noPriorTherapy: boolean,
    psoriasisArthritis_inadequateResponseToDMARD: boolean,

    // Kontraindikation
    psoriasisArthritis_contraindication: string,

    // vorgesehen Therapie
    psoriasisArthritis_monotherapy: boolean,
    psoriasisArthritis_combinationTherapyWithMTX: boolean,
    psoriasisArthritis_combinationTherapyWithOtherMedication: boolean,
    psoriasisArthritis_medicationForCombinationTherapy: string,

    // Morbus Crohn
    // Morbus Crohn Limitationen
    morbusCrohn_dermatologySpecialist: boolean,
    morbusCrohn_universityClinic: boolean,

    // Behandlungsvorhaben
    morbusCrohn_erstbehandlungMitStelara: boolean,
    morbusCrohn_verkuerzungDesDosierungsintervalls: boolean,
    morbusCrohn_weiterbehandlung: boolean,
    morbusCrohn_BeschreibungVorbehandlung: string,
    morbusCrohn_BeschreibungTherapie: string,

    // Morbus Crohn vorangegangene Therapie
    morbusCrohn_behandlungMittelschweremBisSchweremAktiven: boolean,
    morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: boolean,

    // Morbus Crohn Behandlungsvorhaben
    morbusCrohn_monoTherapie: boolean,
    morbusCrohn_kombinationstherapie: boolean,
    morbusCrohn_kombinationstherapieMedikament: string,

    // Colitis Ulcerosa
    colitisUlcerosa_dermatologySpecialist: boolean,
    colitisUlcerosa_universityClinic: boolean,

    // Behandlungsvorhaben
    colitisUlcerosa_erstbehandlungMitStelara: boolean,
    colitisUlcerosa_verkuerzungDesDosierungsintervalls: boolean,
    colitisUlcerosa_weiterbehandlung: boolean,

    // Colitis Ulcerosa vorangegangene Therapie
    colitisUlcerosa_BeschreibungVorbehandlung: string,
    colitisUlcerosa_BeschreibungTherapie: string,

    // vorangegangene Therapie
    colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven: boolean,
    colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: boolean,

    // Behandlungsvorhaben
    colitisUlcerosa_monoTherapie: boolean,
    colitisUlcerosa_kombinationstherapie: boolean,
    colitisUlcerosa_kombinationstherapieMedikament: string


    // default stuff
    preTreatment: string,
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,
  }
}

const initialState: StelaraState = {
  formData: {
    administrationAsPen: false,
    administrationAsPreFilledSyringe: false,
    administrationAsInfusionSolution: false,
    severePlaquePsoriasis: false,
    psoriasisArthritis: false,
    crohnsDisease: false,
    ulcerativeColitis: false,
    otherIndication: false,
    plaquePsoriasis_dlqi_smaller10: false,
    plaquePsoriasis_dlqi_larger10: false,
    plaquePsoriasis_dlqi_pasi: false,
    plaquePsoriasis_dlqi_bsa: false,
    plaquePsoriasis_dlqi_pga: false,
    plaquePsoriasis_dlqi_number: 0,
    plaquePsoriasis_befallVonGesicht: false,
    plaquePsoriasis_befallDerKopfhaut: false,
    plaquePsoriasis_befallDerGenitalien: false,
    plaquePsoriasis_befallDerHaenderFingerUndOderNaegel: false,
    plaquePsoriasis_starkerJuckreiz: false,
    plaquePsoriasis_hartnaeckigePlaques: false,
    plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg: false,
    plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen: false,
    plaquePsoriasis_begruendungDerKontraindikation: "",
    plaquePsoriasis_Phototherapie: false,
    plaquePsoriasis_Ciclosporin: false,
    plaquePsoriasis_Methotrexat: false,
    plaquePsoriasis_Acitretin: false,
    plaquePsoriasis_anderesBiologikum: false,
    plaquePsoriasis_keineVortherapie: false,
    plaquePsoriasis_dermatologySpecialist: false,
    plaquePsoriasis_universityClinic: false,
    plaquePsoriasis_isChildAbove6AndLighterThan60kg: false,
    plaquePsoriasis_isChildAbove6AndHeavierThan60kg: false,
    psoriasisArthritis_dermatologySpecialist: false,
    psoriasisArthritis_universityClinic: false,
    psoriasisArthritis_noPriorTherapy: false,
    psoriasisArthritis_inadequateResponseToDMARD: false,
    psoriasisArthritis_contraindication: "",
    psoriasisArthritis_monotherapy: false,
    psoriasisArthritis_combinationTherapyWithMTX: false,
    psoriasisArthritis_combinationTherapyWithOtherMedication: false,
    psoriasisArthritis_medicationForCombinationTherapy: "",
    morbusCrohn_dermatologySpecialist: false,
    morbusCrohn_universityClinic: false,
    morbusCrohn_erstbehandlungMitStelara: false,
    morbusCrohn_verkuerzungDesDosierungsintervalls: false,
    morbusCrohn_weiterbehandlung: false,
    morbusCrohn_BeschreibungVorbehandlung: "",
    morbusCrohn_BeschreibungTherapie: "",
    morbusCrohn_behandlungMittelschweremBisSchweremAktiven: false,
    morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: false,
    morbusCrohn_monoTherapie: false,
    morbusCrohn_kombinationstherapie: false,
    morbusCrohn_kombinationstherapieMedikament: "",
    colitisUlcerosa_dermatologySpecialist: false,
    colitisUlcerosa_universityClinic: false,
    colitisUlcerosa_erstbehandlungMitStelara: false,
    colitisUlcerosa_verkuerzungDesDosierungsintervalls: false,
    colitisUlcerosa_weiterbehandlung: false,
    colitisUlcerosa_BeschreibungVorbehandlung: "",
    colitisUlcerosa_BeschreibungTherapie: "",
    colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven: false,
    colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: false,
    colitisUlcerosa_monoTherapie: false,
    colitisUlcerosa_kombinationstherapie: false,
    colitisUlcerosa_kombinationstherapieMedikament: "",
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const StelaraFormSlice = createSlice({
  name: "Stelara",
  initialState,
  reducers: {
    onClearStelaraState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedStelaraEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeStelaraValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeStelaraValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedStelaraEditData,
  onClearStelaraState,
  onChangeStelaraValue,
  onAddMedication,
  onRemoveMedication,
} = StelaraFormSlice.actions;

export default StelaraFormSlice.reducer;
export const selectStelaraValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.stelaraForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("updatestate", JSON.stringify(data));
  if (!data) return;

  // Update specific options only if they are provided in data
  if ("administrationAsPen" in data) {
    state.formData.administrationAsPen = !!data.administrationAsPen;
    !isEdit && resetBelow(fieldNames, "administrationAsPen", data, state);
  }
  if ("administrationAsPreFilledSyringe" in data) {
    state.formData.administrationAsPreFilledSyringe = !!data.administrationAsPreFilledSyringe;
    !isEdit && resetBelow(fieldNames, "administrationAsPreFilledSyringe", data, state);
  }
  if ("administrationAsInfusionSolution" in data) {
    state.formData.administrationAsInfusionSolution = !!data.administrationAsInfusionSolution;
    !isEdit && resetBelow(fieldNames, "administrationAsInfusionSolution", data, state);
  }

  // List of possible indications
  const indications = [
    "severePlaquePsoriasis",
    "psoriasisArthritis",
    "crohnsDisease",
    "ulcerativeColitis",
    "otherIndication",
  ];
  // Loop through each indication and update the state only if present in data
  indications.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible DLQI options
  const dlqiOptions = [
    "plaquePsoriasis_dlqi_smaller10",
    "plaquePsoriasis_dlqi_larger10",
    "plaquePsoriasis_dlqi_pasi",
    "plaquePsoriasis_dlqi_bsa",
    "plaquePsoriasis_dlqi_pga",
    "plaquePsoriasis_dlqi_number",
  ];
  // Update each DLQI option only if present in data
  dlqiOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Manifestationen options
  const manifestationenOptions = [
    "plaquePsoriasis_befallVonGesicht",
    "plaquePsoriasis_befallDerKopfhaut",
    "plaquePsoriasis_befallDerGenitalien",
    "plaquePsoriasis_befallDerHaenderFingerUndOderNaegel",
    "plaquePsoriasis_starkerJuckreiz",
    "plaquePsoriasis_hartnaeckigePlaques",
  ];
  // Update each Manifestationen option only if present in data
  manifestationenOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Vorhergehende Therapien (Plaque Psoriasis) options
  const plaquePsoriasisOptions = [
    "plaquePsoriasis_Phototherapie",
    "plaquePsoriasis_Ciclosporin",
    "plaquePsoriasis_Methotrexat",
    "plaquePsoriasis_Acitretin",
    "plaquePsoriasis_anderesBiologikum",
    "plaquePsoriasis_keineVortherapie",
  ];
  // Update each Vorhergehende Therapien (Plaque Psoriasis) option only if present in data
  plaquePsoriasisOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible zutreffenden Grund an für die Anwendung von STELARA options
  const stelaraOptions = [
    "plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg",
    "plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen",
    "plaquePsoriasis_begruendungDerKontraindikation",
  ];
  // Update each zutreffenden Grund an für die Anwendung von STELARA option only if present in data
  stelaraOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie options
  const limitatioOptions = [
    "plaquePsoriasis_dermatologySpecialist",
    "plaquePsoriasis_universityClinic",
  ];
  // Update each Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie option only if present in data
  limitatioOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });


  const psoriasiChild = [
    "plaquePsoriasis_isChildAbove6AndLighterThan60kg",
    "plaquePsoriasis_isChildAbove6AndHeavierThan60kg",
  ];
  // Update each Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie option only if present in data
  psoriasiChild.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });


  // List of possible Psoriasis Arthritis options
  const psoriasisArthritisOptions = [
    "psoriasisArthritis_dermatologySpecialist",
    "psoriasisArthritis_universityClinic",
    "psoriasisArthritis_noPriorTherapy",
    "psoriasisArthritis_inadequateResponseToDMARD",
    "psoriasisArthritis_contraindication",
    "psoriasisArthritis_monotherapy",
    "psoriasisArthritis_combinationTherapyWithMTX",
    "psoriasisArthritis_combinationTherapyWithOtherMedication",
    "psoriasisArthritis_medicationForCombinationTherapy",
  ];
  // Update each Psoriasis Arthritis option only if present in data
  psoriasisArthritisOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Morbus Crohn options
  const morbusCrohnOptions = [
    "morbusCrohn_dermatologySpecialist",
    "morbusCrohn_universityClinic",
    "morbusCrohn_erstbehandlungMitStelara",
    "morbusCrohn_verkuerzungDesDosierungsintervalls",
    "morbusCrohn_weiterbehandlung",
    "morbusCrohn_BeschreibungVorbehandlung",
    "morbusCrohn_BeschreibungTherapie",
    "morbusCrohn_behandlungMittelschweremBisSchweremAktiven",
    "morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",
    "morbusCrohn_monoTherapie",
    "morbusCrohn_kombinationstherapie",
    "morbusCrohn_kombinationstherapieMedikament",
  ];
  // Update each Morbus Crohn option only if present in data
  morbusCrohnOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Colitis Ulcerosa options
  const colitisUlcerosaOptions = [
    "colitisUlcerosa_dermatologySpecialist",
    "colitisUlcerosa_universityClinic",
    "colitisUlcerosa_erstbehandlungMitStelara",
    "colitisUlcerosa_verkuerzungDesDosierungsintervalls",
    "colitisUlcerosa_weiterbehandlung",
    "colitisUlcerosa_BeschreibungVorbehandlung",
    "colitisUlcerosa_BeschreibungTherapie",
    "colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven",
    "colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",
    "colitisUlcerosa_monoTherapie",
    "colitisUlcerosa_kombinationstherapie",
    "colitisUlcerosa_kombinationstherapieMedikament",
  ];
  // Update each Colitis Ulcerosa option only if present in data
  colitisUlcerosaOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  if (isEdit) {
    resetBelow(fieldNames, "", data, state);
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
