import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import DrugPlanForm from "./DrugPlanForm";
import {removeStudy} from "pages/Wizard/wizardSlice";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";


import {
  onChangeTremfyaValue,
  selectTremfyaValues,
} from "./TremfyaSlice";

const TremfyaForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();
  const TremfyaValues = UseAppSelector(selectTremfyaValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const handleValueChangeKVV = (field: string, value: any) => dispatch(onChangeTreatmentValue({field, value}));
  const currentLang = i18n.language.toUpperCase();
  const medicineValues=UseAppSelector(selectMedicineValues);
  const isPen = medicineValues?.pharmacode === "7766043";
  const [variant, setVariant] = useState(0);
  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      dispatch(onChangeDosisValue({field: "dosis", value: t("tremfya.dose")}));
    }
  }, [
    params.pathname,
  ]);

  useEffect(() => {
    // if (isEdit) return;
    setVariant(0);
    // Variant 1
    if (
      TremfyaValues.indication_schwerePlaquePsoriasis &&
      TremfyaValues.plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg === false &&
      TremfyaValues.plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen === false &&
      (
        TremfyaValues.plaquePsoriasisfacharztFuerDermatologieIn ||
        TremfyaValues.plaquePsoriasisuniversitaetsklinikPoliklinik
      )
    ) {
      setVariant(1);
    }

    // Variant 2
    if (
      TremfyaValues.indication_schwerePlaquePsoriasis &&
          !TremfyaValues.plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg &&
          !TremfyaValues.plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen &&
          !TremfyaValues.plaquePsoriasisfacharztFuerDermatologieIn &&
          !TremfyaValues.plaquePsoriasisuniversitaetsklinikPoliklinik

    ) {
      setVariant(2);
    }
    // Variant 3
    if (
      TremfyaValues.indication_psoriasisArthritis &&
      (
        TremfyaValues.psoriasisArthritisfacharztFuerDermatologieIn ||
        TremfyaValues.psoriasisArthritisuniversitaetsklinikPoliklinik
      ) &&
      TremfyaValues.psoriasisArthritisBehandlungCSDMARD &&
      (
        TremfyaValues.psoriasisArthritisMonotherapie ||
        TremfyaValues.psoriasisArthritisKombinationstherapieMethotrexat
      )
    ) {
      setVariant(3);
    }

    // Variant 4
    if (
      TremfyaValues.indication_psoriasisArthritis &&
      TremfyaValues.psoriasisArthritisBehandlungCSDMARD &&
          (
            TremfyaValues.psoriasisArthritisfacharztFuerDermatologieIn ||
            TremfyaValues.psoriasisArthritisuniversitaetsklinikPoliklinik
          ) &&
          TremfyaValues.psoriasisArthritisKombinationstherapieOther
    ) {
      setVariant(4);
    }

    // Variant 5
    if (
      TremfyaValues.indication_psoriasisArthritis &&
          (
            TremfyaValues.psoriasisArthritisfacharztFuerDermatologieIn ||
            TremfyaValues.psoriasisArthritisuniversitaetsklinikPoliklinik
          )
    ) {
      setVariant(5);
    }
  }, [
    // Variant 1 and 2
    TremfyaValues.indication_schwerePlaquePsoriasis,
    TremfyaValues.plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg,
    TremfyaValues.plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen,
    TremfyaValues.plaquePsoriasisfacharztFuerDermatologieIn,
    TremfyaValues.plaquePsoriasisuniversitaetsklinikPoliklinik,
    // variant 3
    TremfyaValues.indication_psoriasisArthritis,
    TremfyaValues.psoriasisArthritisfacharztFuerDermatologieIn,
    TremfyaValues.psoriasisArthritisuniversitaetsklinikPoliklinik,
    TremfyaValues.psoriasisArthritisBehandlungCSDMARD,
    TremfyaValues.psoriasisArthritisMonotherapie,
    TremfyaValues.plaquePsoriasisMethotrexat,
    TremfyaValues.psoriasisArthritisKombinationstherapieMethotrexat,
    // variant 4
    TremfyaValues.psoriasisArthritisKombinationstherapieOther,
  ]);


  useEffect(() => {
    // DEFAULT
    if (variant === 0) {
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      dispatch(removeStudy({}));
    }
    if (variant === 1) {
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));

      if (isPen) {
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21007.01"}));
      } else {
        dispatch(onChangeMedicineValue({field: "kn_id", value: "20752.01"}));
      }
      dispatch(removeStudy({}));
    }
    if (variant === 2) {
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      dispatch(removeStudy({}));
    }
    if (variant === 3) {
      if (isPen) {
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21007.02"}));
      } else {
        dispatch(onChangeMedicineValue({field: "kn_id", value: "20752.02"}));
      }
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      dispatch(removeStudy({}));
    }
    if (variant === 4) {
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      dispatch(removeStudy({}));
    }
    if (variant === 5) {
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      dispatch(removeStudy({}));
    }
  }, [variant]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} hidden={false}>
          <FormControl>
            <FormLabel>{t("tremfya.options")}</FormLabel>
            <RadioGroup
              aria-labelledby="tremfya-radio-buttons-group-options"
              name="tremfya-radio-buttons-group-options"
              onChange={(event, value) => {
                const isPen = value === "options_isPen";
                dispatch(onChangeTremfyaValue({formData: {options_isPen: isPen, options_isNotPen: !isPen}}));
              }}
            >
              {[
                {value: "options_isPen", checked: TremfyaValues.options_isPen},
                {value: "options_isNotPen", checked: TremfyaValues.options_isNotPen},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={

          (TremfyaValues.options_isPen && isPen) ||
          (TremfyaValues.options_isNotPen && !isPen)

        } >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("tremfya.warning.pharmacode")}</Typography>
          </Stack>
          <Box sx={{m: 2}} />
        </Grid>

        <Grid item xs={12} hidden={
          !(TremfyaValues.options_isPen || TremfyaValues.options_isNotPen)
        }>
          <FormControl>
            <FormLabel>{t("tremfya.indication")}</FormLabel>
            <RadioGroup
              aria-labelledby="tremfya-radio-buttons-group-indication"
              name="tremfya-radio-buttons-group-indication"
              onChange={(event, value) => {
                dispatch(
                    onChangeTremfyaValue({
                      formData: {
                        indication_schwerePlaquePsoriasis: value === "indication_schwerePlaquePsoriasis",
                        indication_psoriasisArthritis: value === "indication_psoriasisArthritis",
                        indication_other: value === "indication_other",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "indication_schwerePlaquePsoriasis", checked: TremfyaValues.indication_schwerePlaquePsoriasis},
                {value: "indication_psoriasisArthritis", checked: TremfyaValues.indication_psoriasisArthritis},
                {value: "indication_other", checked: TremfyaValues.indication_other},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        {
          // Indication: Schwere Plaque-Psoriasis
        }
        <Grid item xs={12} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <Typography>{t("tremfya.dlqi.explaination")}</Typography>
          <Box sx={{m: 2}} />
        </Grid>

        <Grid item xs={3} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <FormControl fullWidth>
            <InputLabel id="tremfya-dlqi-label">{t("tremfya.dlqi")}</InputLabel>
            <Select
              labelId="tremfya-dlqi-label"
              id="tremfya-dlqi-select"
              value={
        TremfyaValues.dlqi_smaller10 ?
          "dlqi_smaller10" :
          TremfyaValues.dlqi_larger10 ?
          "dlqi_larger10" :
          ""
              }
              onChange={(event) => {
                const value = event.target.value;
                dispatch(
                    onChangeTremfyaValue({
                      formData: {
                        dlqi_smaller10: value === "dlqi_smaller10",
                        dlqi_larger10: value === "dlqi_larger10",
                      },
                    }),
                );
              }}
            >
              <MenuItem value="dlqi_smaller10" data-cy="dlqi_smaller10">
                {t("tremfya.dlqi_smaller10")}
              </MenuItem>
              <MenuItem value="dlqi_larger10" data-cy="dlqi_larger10">
                {t("tremfya.dlqi_larger10")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={3} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <FormControl fullWidth>
            <InputLabel id="tremfya-dlqi-label">{t("tremfya.dlqi.befall")}</InputLabel>
            <Select
              labelId="tremfya-dlqi-label"
              id="tremfya-dlqi-select"
              value={
        TremfyaValues.dlqi_pasi ?
          "dlqi_pasi" :
          TremfyaValues.dlqi_bsa ?
          "dlqi_bsa" :
          TremfyaValues.dlqi_pga ?
          "dlqi_pga" :
          ""
              }
              onChange={(event) => {
                const value = event.target.value;
                dispatch(
                    onChangeTremfyaValue({
                      formData: {
                        dlqi_pasi: value === "dlqi_pasi",
                        dlqi_bsa: value === "dlqi_bsa",
                        dlqi_pga: value === "dlqi_pga",
                      },
                    }),
                );
              }}
            >
              <MenuItem value="dlqi_pasi" data-cy="dlqi_pasi">
                {t("tremfya.dlqi_pasi")}
              </MenuItem>
              <MenuItem value="dlqi_bsa" data-cy="dlqi_bsa">
                {t("tremfya.dlqi_bsa")}
              </MenuItem>
              <MenuItem value="dlqi_pga" data-cy="dlqi_pga">
                {t("tremfya.dlqi_pga")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={3} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <TextField
            label={t("tremfya.dlqi_number")}
            variant="outlined"
            fullWidth
            data-cy="dlqi_number"
            value={TremfyaValues.dlqi_number}
            onChange={(event) => {
              const value = event.target.value;
              if (/^\d*$/.test(value)) { // Allows only digits (0-9)
                dispatch(
                    onChangeTremfyaValue({
                      formData: {dlqi_number: value},
                    }),
                );
              }
            }}
            inputProps={{
              inputMode: "numeric", // Optimizes for numeric keyboards on mobile
              pattern: "[0-9]*", // Ensures only numeric input
            }}
          />
        </Grid>


        <Grid item xs={12} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <FormControl>
            <FormLabel>{t("tremfya.manifestationen")}</FormLabel>
            <FormGroup>
              {[
                {value: "befallVonGesicht", checked: TremfyaValues.befallVonGesicht},
                {value: "befallDerKopfhaut", checked: TremfyaValues.befallDerKopfhaut},
                {value: "befallDerGenitalien", checked: TremfyaValues.befallDerGenitalien},
                {value: "befallDerHaenderFingerUndOderNaegel", checked: TremfyaValues.befallDerHaenderFingerUndOderNaegel},
                {value: "starkerJuckreiz", checked: TremfyaValues.starkerJuckreiz},
                {value: "hartnaeckigePlaques", checked: TremfyaValues.hartnaeckigePlaques},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis
        }>
          <FormControl>
            <FormLabel>{t("tremfya.vorhergehendeTherapienPlaquePsoriasis")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoriasisPhototherapie", checked: TremfyaValues.plaquePsoriasisPhototherapie},
                {value: "plaquePsoriasisCiclosporin", checked: TremfyaValues.plaquePsoriasisCiclosporin},
                {value: "plaquePsoriasisMethotrexat", checked: TremfyaValues.plaquePsoriasisMethotrexat},
                {value: "plaquePsoriasisAcitretin", checked: TremfyaValues.plaquePsoriasisAcitretin},
                {value: "plaquePsoriasisAndereBiologikumBiologika", checked: TremfyaValues.plaquePsoriasisAndereBiologikumBiologika},
                {value: "plaquePsoriasisKeineVortherapie", checked: TremfyaValues.plaquePsoriasisKeineVortherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis ||
          !(
            TremfyaValues.plaquePsoriasisPhototherapie ||
            TremfyaValues.plaquePsoriasisCiclosporin ||
            TremfyaValues.plaquePsoriasisMethotrexat ||
            TremfyaValues.plaquePsoriasisAcitretin ||
            TremfyaValues.plaquePsoriasisAndereBiologikumBiologika
          )

        }>
          <FormControl>
            <FormLabel>{t("tremfya.zutreffendenGrund")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg", checked: TremfyaValues.plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg},
                {value: "plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen", checked: TremfyaValues.plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>

          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_schwerePlaquePsoriasis ||
          !TremfyaValues.plaquePsoriasisKeineVortherapie
        }>
          <FormControl>
            <Typography hidden={!TremfyaValues.plaquePsoriasisKeineVortherapie}
            >{t("tremfya.plaquePsoriasisKontraindikation")}</Typography>
            <Box sx={{m: 1}} />
            <TextField
              hidden={!TremfyaValues.plaquePsoriasisKeineVortherapie}
              label={t("tremfya.justification")}
              variant="outlined"
              fullWidth
              data-cy="plaquePsoriasisKontraindikation"
              value={TremfyaValues.plaquePsoriasisKontraindikation}
              onChange={(event) =>
                dispatch(
                    onChangeTremfyaValue({
                      formData: {plaquePsoriasisKontraindikation: event.target.value},
                    }),
                )
              }
            />
          </FormControl>
        </Grid>


        <Grid item xs={12} hidden={!TremfyaValues.indication_schwerePlaquePsoriasis }>
          <Box sx={{m: 2}} />
          <FormLabel>{t("tremfya.limitatio")}</FormLabel>
          <FormGroup>
            {[
              {value: "plaquePsoriasisfacharztFuerDermatologieIn", checked: TremfyaValues.plaquePsoriasisfacharztFuerDermatologieIn},
              {value: "plaquePsoriasisuniversitaetsklinikPoliklinik", checked: TremfyaValues.plaquePsoriasisuniversitaetsklinikPoliklinik},
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={(event) =>
                      dispatch(
                          onChangeTremfyaValue({
                            formData: {[option.value]: event.target.checked},
                          }),
                      )
                    }
                    data-cy={option.value}
                  />
                }
                label={t(`tremfya.${option.value}`)}
              />
            ))}
          </FormGroup>
        </Grid>

        {
          // Indication: Psoriasis-Arthritis
        }

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_psoriasisArthritis
        }>
          <FormControl>
            <FormLabel>{t("tremfya.vortherapie")}</FormLabel>
            <RadioGroup
              aria-labelledby="tremfya-radio-buttons-group-vortherapie"
              name="tremfya-radio-buttons-group-vortherapie"
              onChange={(event, value) => {
                dispatch(
                    onChangeTremfyaValue({
                      formData: {
                        psoriasisArthritisBehandlungCSDMARD: value === "psoriasisArthritisBehandlungCSDMARD",
                        psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie: value === "psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "psoriasisArthritisBehandlungCSDMARD", checked: TremfyaValues.psoriasisArthritisBehandlungCSDMARD},
                {value: "psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie", checked: TremfyaValues.psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_psoriasisArthritis ||
          !TremfyaValues.psoriasisArthritisBehandlungCSDMARD

        }>
          <FormControl>
            <FormLabel>{t("tremfya.vorhergehendeTherapienPsoriasisArthritis")}</FormLabel>
            <FormGroup>
              {[
                {value: "psoriasisArthritisMethotrexat", checked: TremfyaValues.psoriasisArthritisMethotrexat},
                {value: "psoriasisArthritisLeflunomid", checked: TremfyaValues.psoriasisArthritisLeflunomid},
                {value: "psoriasisArthritisSulfasalazin", checked: TremfyaValues.psoriasisArthritisSulfasalazin},
                {value: "psoriasisArthritisAndereBiologikumBiologika", checked: TremfyaValues.psoriasisArthritisAndereBiologikumBiologika},
                {value: "psoriasisArthritisKeine", checked: TremfyaValues.psoriasisArthritisKeine},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_psoriasisArthritis ||
          (
            !TremfyaValues.psoriasisArthritisMethotrexat &&
            !TremfyaValues.psoriasisArthritisLeflunomid &&
            !TremfyaValues.psoriasisArthritisSulfasalazin &&
            !TremfyaValues.psoriasisArthritisAndereBiologikumBiologika
          )

        }>
          <FormControl>
            <FormLabel>{t("tremfya.grundAnwendung")}</FormLabel>
            <FormGroup>
              {[
                {value: "psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg", checked: TremfyaValues.psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg},
                {value: "psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen", checked: TremfyaValues.psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}

            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_psoriasisArthritis ||
          !TremfyaValues.psoriasisArthritisKeine}>
          <Typography>{t("tremfya.psoriasisArthritisKontraindikation")}</Typography>
          <Box sx={{m: 1}} />
          <TextField
            label={t("tremfya.justification")}
            variant="outlined"
            fullWidth
            data-cy="psoriasisArthritisKontraindikation"
            value={TremfyaValues.psoriasisArthritisKontraindikation}
            onChange={(event) =>
              dispatch(
                  onChangeTremfyaValue({
                    formData: {psoriasisArthritisKontraindikation: event.target.value},
                  }),
              )
            }
          />
        </Grid>

        <Grid item xs={12} hidden={
          !TremfyaValues.indication_psoriasisArthritis
        }>
          <FormControl>
            <FormLabel>{t("tremfya.kombinationstherapie")}</FormLabel>
            <RadioGroup
              aria-labelledby="tremfya-radio-buttons-group-kombinationstherapie"
              name="tremfya-radio-buttons-group-kombinationstherapie"
              onChange={(event, value) => {
                dispatch(
                    onChangeTremfyaValue({
                      formData: {
                        psoriasisArthritisMonotherapie: value === "psoriasisArthritisMonotherapie",
                        psoriasisArthritisKombinationstherapieMethotrexat: value === "psoriasisArthritisKombinationstherapieMethotrexat",
                        psoriasisArthritisKombinationstherapieOther: value === "psoriasisArthritisKombinationstherapieOther",

                      },
                    }),
                );
              }}
            >
              {[
                {value: "psoriasisArthritisMonotherapie", checked: TremfyaValues.psoriasisArthritisMonotherapie},
                {value: "psoriasisArthritisKombinationstherapieMethotrexat", checked: TremfyaValues.psoriasisArthritisKombinationstherapieMethotrexat},
                {value: "psoriasisArthritisKombinationstherapieOther", checked: TremfyaValues.psoriasisArthritisKombinationstherapieOther},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </RadioGroup>
            <Typography
              hidden={!TremfyaValues.psoriasisArthritisKombinationstherapieOther}
            >{t("tremfya.psoriasisArthritisKombinationstherapieOtherMedication")}</Typography>

            <DrugPlanForm
              hidden={!TremfyaValues.psoriasisArthritisKombinationstherapieOther}
              label={t("tremfya.medication")}
              data-cy="psoriasisArthritisKombinationstherapieOtherMedication"
              onChange={(newValue) =>
                dispatch(onChangeTremfyaValue({formData: {psoriasisArthritisKombinationstherapieOtherMedication: newValue}}))}
              value={TremfyaValues.psoriasisArthritisKombinationstherapieOtherMedication || ""}
            />
            <Box sx={{m: 2}} />
            <Stack
              hidden={!TremfyaValues.psoriasisArthritisKombinationstherapieOther}
              direction="row"
              alignItems="left"
              gap={1}
            >
              <WarningIcon color={"primary"}></WarningIcon>
              <Typography>{t("tremfya.warning.offlabel")}</Typography>
            </Stack>

          </FormControl>
          <Grid item xs={12} hidden={!TremfyaValues.indication_psoriasisArthritis} >

            <Box sx={{m: 2}} />
            <FormLabel>{t("tremfya.limitatio")}</FormLabel>
            <FormGroup>
              {[
                {value: "psoriasisArthritisfacharztFuerDermatologieIn", checked: TremfyaValues.psoriasisArthritisfacharztFuerDermatologieIn},
                {value: "psoriasisArthritisuniversitaetsklinikPoliklinik", checked: TremfyaValues.psoriasisArthritisuniversitaetsklinikPoliklinik},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>

          </Grid>
        </Grid>


        {
          // Indication: Plaque Psoraisis or Psoriasis Arthritis
        }

        <Grid item xs={12} hidden={

          !(
            TremfyaValues.indication_schwerePlaquePsoriasis ||
            TremfyaValues.indication_psoriasisArthritis
          )
        }>
          <Box sx={{m: 3}} />
          <FormControl>
            <FormLabel>{t("tremfya.plaquePsoraisisBehandlung")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoraisisErhaltungstherapieAlle8Wochen", checked: TremfyaValues.plaquePsoraisisErhaltungstherapieAlle8Wochen},
                {value: "plaquePsoraisisAnpassungDesBehandlungsintervalls", checked: TremfyaValues.plaquePsoraisisAnpassungDesBehandlungsintervalls},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(
                            onChangeTremfyaValue({
                              formData: {[option.value]: event.target.checked},
                            }),
                        )
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`tremfya.${option.value}`)}
                />
              ))}
            </FormGroup>

            <Stack
              hidden={!TremfyaValues.plaquePsoraisisAnpassungDesBehandlungsintervalls}
              direction="row"
              alignItems="left"
              gap={1}
            >
              <WarningIcon color={"primary"}></WarningIcon>
              <Typography>{t("tremfya.warning.plaquePsoraisisBegruendung")}</Typography>
            </Stack>
            <Box
              hidden={!TremfyaValues.plaquePsoraisisAnpassungDesBehandlungsintervalls}
              sx={{m: 1}} />
            <TextField
              hidden={!TremfyaValues.plaquePsoraisisAnpassungDesBehandlungsintervalls}
              label={t("tremfya.justification")}
              variant="outlined"
              fullWidth
              data-cy="plaquePsoraisisBegruendung"
              value={TremfyaValues.plaquePsoraisisBegruendung}
              onChange={(event) =>
                dispatch(
                    onChangeTremfyaValue({
                      formData: {plaquePsoraisisBegruendung: event.target.value},
                    }),
                )
              }
            />
          </FormControl>
        </Grid>


        <Grid
          hidden={
            !(TremfyaValues.indication_other)
          }
          item xs={12}>
          <Typography>{t("tremfya.indication_other.title")}</Typography>
          <Box sx={{m: 3}} />
          {[
            {label: "preTreatment", dataCy: "preTreatment", value: TremfyaValues.preTreatment},
            {label: "patientCondition", dataCy: "patientCondition", value: TremfyaValues.patientCondition},
            {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: TremfyaValues.reasonsAlternativeTherapiesFailed},
            {label: "prognosis", dataCy: "prognosis", value: TremfyaValues.prognosis},
          ].map((field, index) => (
            <React.Fragment key={field.label}>
              <TextField
                style={{width: "100%"}}
                label={t(field.label)}
                data-cy={field.dataCy}
                InputLabelProps={{shrink: true}}
                value={field.value}
                variant="outlined"
                maxRows={4}
                minRows={4}
                multiline
                onChange={(event) =>
                  dispatch(
                      onChangeTremfyaValue({
                        [field.label]: event.target.value,
                      }),
                  )
                }
              />
              <Box sx={{m: 3}} />
            </React.Fragment>
          ))}
        </Grid>
        {variant > 0 && <Grid item xs={12}>
          <Typography
            data-cy="variant"
          >{t("tremfya.variant")} {variant}</Typography>
        </Grid>}
      </Grid>
    </LocalizationProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(TremfyaForm);
