import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  "options_isher2",
  "options_isother",
  "chk_trastuzumab",
  "chk_pertuzumab",
  "chk_trastuzumabEmtansin",
  "chk_trastuzumabDeruxtecan",
  "chk_other",
  "chk_other_txt",
  "chk_incombination_trastuzumabCapecitabin",
  "chk_incombination_other",
  "chk_incombination_other_txt",
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",
];

export interface TukysaState {
  formData: {
    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,

    // part 1
    options_isher2: boolean,
    options_isother: boolean,

    // part 2
    chk_trastuzumab: boolean,
    chk_pertuzumab: boolean,
    chk_trastuzumabEmtansin: boolean,
    chk_trastuzumabDeruxtecan: boolean,
    chk_other: boolean,
    chk_other_txt: string,

    // part 3
    chk_incombination_trastuzumabCapecitabin: boolean,
    chk_incombination_other: boolean,
    chk_incombination_other_txt: string,
  }
}

const initialState:TukysaState = {
  formData: {
  // default stuff
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",

    options_isher2: false,
    options_isother: false,

    chk_trastuzumab: false,
    chk_pertuzumab: false,
    chk_trastuzumabEmtansin: false,
    chk_trastuzumabDeruxtecan: false,
    chk_other: false,
    chk_other_txt: "",

    chk_incombination_trastuzumabCapecitabin: true,
    chk_incombination_other: true,
    chk_incombination_other_txt: "",

  },
};

const TukysaFormSlice = createSlice({
  name: "Tukysa",
  initialState,
  reducers: {
    onClearTukysaState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedTukysaEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
        state.formData.chk_other_txt =
          payload.formData?.chk_other_txt;
        state.formData.chk_incombination_other_txt =
          payload.formData?.chk_incombination_other_txt;
      }
    },
    onChangeTukysaValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeTukysaValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      if (payload.chk_other_txt || payload.chk_other_txt === "") state.formData.chk_other_txt = payload.chk_other_txt;
      if (payload.chk_incombination_other_txt || payload.chk_incombination_other_txt === "") state.formData.chk_incombination_other_txt = payload.chk_incombination_other_txt;

      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedTukysaEditData,
  onClearTukysaState,
  onChangeTukysaValue,
  onAddMedication,
  onRemoveMedication,
} = TukysaFormSlice.actions;

export default TukysaFormSlice.reducer;
export const selectTukysaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.tukysaForm.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  if (data.medications) {
    state.formData.medications = data.medications;
  }

  if ("options_isother" in data ) {
    state.formData.options_isother = !!data.options_isother;
    !isEdit && resetBelow(fieldNames, "options_isother", data, state);
  }
  if ("options_isher2" in data) {
    state.formData.options_isher2 = !!data.options_isher2;
    !isEdit && resetBelow(fieldNames, "options_isother", data, state);
  }
  ["chk_trastuzumab", "chk_pertuzumab", "chk_trastuzumabEmtansin", "chk_trastuzumabDeruxtecan", "chk_other"].forEach((key) => {
    if (key in data) {
      state.formData[key] = !!data[key];
      key === "chk_other" && !isEdit && resetBelow(fieldNames, "chk_other", data, state);
    }
  });
  if ("chk_other_txt" in data) {
    state.formData.chk_other_txt = data.chk_other_txt;
  }


  ["chk_incombination_trastuzumabCapecitabin", "chk_incombination_other"].forEach((key) => {
    if (key in data) {
      state.formData[key] = !!data[key];
      !isEdit && resetBelow(fieldNames, "chk_incombination_other", data, state);
    }
  });
  if ("chk_incombination_other_txt" in data) {
    state.formData.chk_incombination_other_txt = data.chk_incombination_other_txt;
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
