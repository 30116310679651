import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";

import {
  onChangeSimponiValue,
  selectSimponiValues,
} from "./SimponiSlice";

import DrugPlanForm from "./DrugPlanForm";


const SimponiForm = (props) => {
  // usestate for variant, numeric value:
  const [variant, setVariant] = useState(0);

  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const SimponiValues = UseAppSelector(selectSimponiValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const medicineValues=UseAppSelector(selectMedicineValues);

  const isPen =
    medicineValues?.pharmacode === "4665340" ||
    medicineValues?.pharmacode === "5890168";


  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);


  useEffect(() => {
    setVariant(0);
    console.debug("SimponiValues changed", SimponiValues);
    // Variant 1
    if ( SimponiValues.morbusBechterew && SimponiValues.nsaidsTherapyInsufficient) {
      setVariant(1);
    }
    if (
      SimponiValues.ulcerativeColitis &&
      SimponiValues.ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated) {
      setVariant(2);
    }
    if (
      (
        SimponiValues.rheumatoidArthritis || SimponiValues.psoriaticArthritis
      ) &&
      SimponiValues.dmardsTherapyInsufficient &&
      SimponiValues.arthritisCombinationTherapyWithMethotrexate
    ) {
      setVariant(3);
    }

    if (
      (
        SimponiValues.rheumatoidArthritis || SimponiValues.psoriaticArthritis
      ) &&
      SimponiValues.dmardsTherapyInsufficient &&
      (
        SimponiValues.arthritisCombinationTherapyWithOtherMedication ||
        SimponiValues.arthritisMonotherapy
      )
    ) {
      setVariant(4);
    }
  }, [
    SimponiValues.morbusBechterew,
    SimponiValues.nsaidsTherapyInsufficient,
    SimponiValues.ulcerativeColitis,
    SimponiValues.ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated,
    SimponiValues.rheumatoidArthritis,
    SimponiValues.psoriaticArthritis,
    SimponiValues.dmardsTherapyInsufficient,
    SimponiValues.rheumatoidArthritisMethotrexat,
    SimponiValues.rheumatoidArthritisLeflunomid,
    SimponiValues.rheumatoidArthritisSulfasalazin,
    SimponiValues.rheumatoidArthritisAndereBiologikum,
    SimponiValues.arthritisCombinationTherapyWithMethotrexate,
    SimponiValues.arthritisCombinationTherapyWithOtherMedication,
    SimponiValues.arthritisMonotherapy,
  ]);


  useEffect(() => {
    console.debug("SimponiValues changed", SimponiValues);
    switch (variant) {
      case 1:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("simponi.dosis.label.fi")}));
        break;
      case 2:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("")}));
        break;
      case 3:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("simponi.dosis.label.fi")}));
        break;
      case 4:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("simponi.dosis.label.fi")}));
        break;
      default:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        break;
    }
  }, [
    variant,
  ]);


  // SimponiValues.line1_

  return <>

    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
      <Grid item container xs={12} spacing={2}>

        <Grid item xs={12} hidden={false}>
          <FormControl>
            <FormLabel>{t("simponi.administrationMethod")}</FormLabel>
            <RadioGroup
              aria-labelledby="simponi-radio-buttons-group-administration"
              name="simponi-radio-buttons-group-administration"
              onChange={(event, value) => {
                const isPen = value === "administrationAsPen";
                dispatch(onChangeSimponiValue({formData: {administrationAsPen: isPen, administrationAsSyringe: !isPen}}));
              }}
            >
              {[
                {value: "administrationAsPen", checked: SimponiValues.administrationAsPen},
                {value: "administrationAsSyringe", checked: SimponiValues.administrationAsSyringe},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`simponi.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          (SimponiValues.administrationAsPen && isPen) ||
          (SimponiValues.administrationAsSyringe && !isPen)
        } >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("tremfya.warning.pharmacode")}</Typography>
          </Stack>
          <Box sx={{m: 2}} />
        </Grid>


        <Grid item xs={12}
          hidden={!SimponiValues.administrationAsPen && !SimponiValues.administrationAsSyringe}

        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("simponi.indications")}</FormLabel>
            <RadioGroup
              aria-labelledby="simponi-radio-buttons-group-indications"
              name="simponi-radio-buttons-group-indications"
              value={Object.keys(SimponiValues).find((key) => SimponiValues[key] === true) || ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const updatedValues = {
                  rheumatoidArthritis: false,
                  psoriaticArthritis: false,
                  morbusBechterew: false,
                  ulcerativeColitis: false,
                  otherIndication: false,
                  [selectedValue]: true,
                };
                dispatch(onChangeSimponiValue({formData: updatedValues}));
              }}
            >
              {[
                {label: "rheumatoidArthritis", value: SimponiValues.rheumatoidArthritis},
                {label: "psoriaticArthritis", value: SimponiValues.psoriaticArthritis},
                {label: "morbusBechterew", value: SimponiValues.morbusBechterew},
                {label: "ulcerativeColitis", value: SimponiValues.ulcerativeColitis},
                {label: "otherIndication", value: SimponiValues.otherIndication},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  value={field.label}
                  control={<Radio checked={field.value} data-cy={field.label} />}
                  label={t(`simponi.options.${field.label}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("simponi.therapyInsufficient")}</FormLabel>
            <FormGroup
              hidden={
                !SimponiValues.morbusBechterew
              }
            >
              {[
                {label: "nsaidsTherapyInsufficient", value: SimponiValues.nsaidsTherapyInsufficient},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeSimponiValue({
                              formData: {[field.label]: event.target.checked},
                            }),
                        )
                      }
                      name={field.label}
                      data-cy={field.label}
                    />
                  }
                  label={t(`simponi.${field.label}`)}
                />
              ))}
            </FormGroup>
            <FormGroup
              hidden={
                !SimponiValues.rheumatoidArthritis &&
                !SimponiValues.psoriaticArthritis
              }
            >
              {[
                {label: "dmardsTherapyInsufficient", value: SimponiValues.dmardsTherapyInsufficient},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeSimponiValue({
                              formData: {[field.label]: event.target.checked},
                            }),
                        )
                      }
                      name={field.label}
                      data-cy={field.label}
                    />
                  }
                  label={t(`simponi.${field.label}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>


        {
        /*
        * Rheumatoid Arthritis
        */
        }

        <Grid item xs={12}
          hidden = {
            (!SimponiValues.rheumatoidArthritis && !SimponiValues.psoriaticArthritis) ||
            !SimponiValues.dmardsTherapyInsufficient

          }
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("simponi.previousTherapies")}</FormLabel>
            <FormGroup>
              {[
                {label: "rheumatoidArthritisMethotrexat", value: SimponiValues.rheumatoidArthritisMethotrexat},
                {label: "rheumatoidArthritisLeflunomid", value: SimponiValues.rheumatoidArthritisLeflunomid},
                {label: "rheumatoidArthritisSulfasalazin", value: SimponiValues.rheumatoidArthritisSulfasalazin},
                {label: "rheumatoidArthritisAndereBiologikum", value: SimponiValues.rheumatoidArthritisAndereBiologikum},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeSimponiValue({
                              formData: {[field.label]: event.target.checked},
                            }),
                        )
                      }
                      name={field.label}
                      data-cy={field.label}
                    />
                  }
                  label={t(`simponi.${field.label}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden = {
            !SimponiValues.rheumatoidArthritis &&
            !SimponiValues.psoriaticArthritis &&
            !SimponiValues.morbusBechterew
          }
        >
          <Typography>
            {t("simponi.explaination.previous.therapy")}
          </Typography>
          <Box sx={{m: 1}} />
          <TextField
            fullWidth
            label={t("simponi.therapieRemarks")}
            data-cy="therapieRemarks"
            InputLabelProps={{shrink: true}}
            value={SimponiValues.therapieRemarks}
            variant="outlined"
            onChange={(event) =>
              dispatch(
                  onChangeSimponiValue({
                    formData: {therapieRemarks: event.target.value},
                  }),
              )
            }
          />
        </Grid>


        <Grid item xs={12}

          hidden = {
            !SimponiValues.rheumatoidArthritis &&
            !SimponiValues.psoriaticArthritis
          }
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("simponi.arthritisTherapies")}</FormLabel>
            <RadioGroup
              aria-labelledby="simponi-radio-buttons-group-arthritis-therapies"
              name="simponi-radio-buttons-group-arthritis-therapies"
              value={Object.keys(SimponiValues).find((key) => SimponiValues[key] === true) || ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const updatedValues = {
                  arthritisCombinationTherapyWithMethotrexate: false,
                  arthritisCombinationTherapyWithOtherMedication: false,
                  arthritisMonotherapy: false,
                  [selectedValue]: true,
                };
                dispatch(onChangeSimponiValue({formData: updatedValues}));
              }}
            >
              {[
                {label: "arthritisCombinationTherapyWithMethotrexate", value: SimponiValues.arthritisCombinationTherapyWithMethotrexate},
                {label: "arthritisCombinationTherapyWithOtherMedication", value: SimponiValues.arthritisCombinationTherapyWithOtherMedication},
                {label: "arthritisMonotherapy", value: SimponiValues.arthritisMonotherapy},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  value={field.label}
                  control={<Radio checked={field.value} data-cy={field.label} />}
                  label={t(`simponi.${field.label}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 2}} />
          <Stack hidden={
            !SimponiValues.arthritisCombinationTherapyWithMethotrexate &&
            !SimponiValues.arthritisCombinationTherapyWithOtherMedication
          } direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("simponi.warning.dosage")}</Typography>
          </Stack>
        </Grid>


        <Grid item xs={12}
          hidden = {!SimponiValues.arthritisCombinationTherapyWithOtherMedication}
        >
          <DrugPlanForm
            hidden={!SimponiValues.arthritisCombinationTherapyWithOtherMedication}
            label={t("simponi.medication")}
            data-cy="psoriasisArthritisKombinationstherapieOtherMedication"
            onChange={(newValue) =>
              dispatch(onChangeSimponiValue({formData: {arthritisCombinationTherapyMedication: newValue}}))}
            value={SimponiValues.arthritisCombinationTherapyMedication || ""}
          />
        </Grid>

        <Grid item xs={12} hidden = {

          !(SimponiValues.rheumatoidArthritis && SimponiValues.arthritisMonotherapy)

        }>
          <Stack direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("simponi.warning.offlabel")}</Typography>
          </Stack>
        </Grid>

        {
          /*
      * Colitis Ulcerosa
      */
        }
        <Grid item xs={12}

          hidden = {!SimponiValues.ulcerativeColitis}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("simponi.ulcerativeColitisTherapies")}</FormLabel>
            <FormGroup>
              {[
                {label: "ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated", value: SimponiValues.ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeSimponiValue({
                              formData: {[field.label]: event.target.checked},
                            }),
                        )
                      }
                      name={field.label}
                      data-cy={field.label}
                    />
                  }
                  label={t(`simponi.${field.label}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12}
          hidden={
            !SimponiValues.otherIndication
          }
        >
          <Stack hidden={false} direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("simponi.warning.offlabel")}</Typography>
          </Stack>
          <Stack hidden={ !(variant === 11)} direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("simponi.warning.otherindication")}</Typography>
          </Stack>
          <Box sx={{m: 3}} />
          {[
            {label: "preTreatment", dataCy: "preTreatment", value: SimponiValues.preTreatment},
            {label: "patientCondition", dataCy: "patientCondition", value: SimponiValues.patientCondition},
            {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: SimponiValues.reasonsAlternativeTherapiesFailed},
            {label: "prognosis", dataCy: "prognosis", value: SimponiValues.prognosis},
          ].map((field, index) => (
            <React.Fragment key={field.label}>
              <TextField
                fullWidth
                label={t(field.label)}
                data-cy={field.dataCy}
                InputLabelProps={{shrink: true}}
                value={field.value}
                variant="outlined"
                maxRows={4}
                minRows={4}
                multiline
                onChange={(event) =>
                  dispatch(
                      onChangeSimponiValue({
                        [field.label]: event.target.value,
                      }),
                  )
                }
              />
              <Box sx={{m: 3}} />
            </React.Fragment>
          ))}
        </Grid>

        {variant > 0 && <Grid item xs={12}>
          <Typography
            data-cy="variant"

          >{t("simponi.variant")} {variant}</Typography>
        </Grid>}
      </Grid>
    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(SimponiForm);
