import * as React from "react";
import {styled} from "@mui/material/styles";
import {TextareaAutosize} from "@mui/material";
import {TextareaAutosizeProps} from "react-textarea-autosize";

export const TextArea = styled(
    React.forwardRef((props: TextareaAutosizeProps, ref: any) => {
      const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const sanitizedValue = event.target.value.replace(/[\r\n\x00-\x1F\x7F]/g, "");
        if (sanitizedValue !== event.target.value) {
          event.target.value = sanitizedValue; // Remove invalid characters
        }
        props.onChange?.(event); // Call the original onChange if provided
      };

      return (
        <TextareaAutosize
          ref={ref}
          {...props}
          onChange={handleInputChange}
        />
      );
    }),
)(({theme}) => ({
  "borderRadius": "4px",
  "borderColor": theme.palette.grey[400],
  "padding": "12px",
  "&::placeholder": {
    color: theme.palette.grey[400],
  },
  "&::-webkit-resizer": {
    display: "none",
  },
  "&:focus-visible": {
    outlineColor: theme.palette.primary.main,
  },
}));
