import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // Indikation
  "aktiveRheumatoideArthritis",
  "morbusCrohn",
  "morbusBechterewAkylosierendeSpondylitis",
  "moderateBisSchwereColitisUlcerosa",
  "schwerePlaquePsoriasis",
  "psoriasisArthritis",
  "andereIndikationSollBehandeltWerden",

  // vorangegangene Standardtherapie mit DMARDs war unzulänglich
  "rheumatoideArthritisPrevNotWorked",

  // vorangegangene konventionelle Therapie (z.B. Azathioprin, Steroide, 6- Mercaptopurin, etc) war unzulänglichin
  "morbusCrohnPrevNotWorked",

  // Limitatio "Morbus Bechterew
  "morbusBechterewPrevNotWorked",
  "facharztFuerRheumatologieInPdfUebernehmen",
  "universitaetsklinikPoliklinik",

  // "Colitis Ulcerosa
  "vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid",

  // plaque Psoriasis bisherige Therapie(n)
  "phototherapieUVBUVA",
  "konventionelleTherapieMitCiclosporin",
  "methotrexat",
  "acitretin",
  "tnfAlphaBlockierendeSubstanz",
  "tnfAlphaBlockierendeSubstanzName",

  // "Psoriasis Arthritis - Bitte wählen Sie die vorangegangene Therapie an
  "kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos",
  "therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos",
  "andereTherapieWarErfolglos",

  // Bitte geben Sie die vorgesehene Therapievariante an:
  "kombinationstherapieMitMethotrexat",
  "kombinationstherapieMitAnderemMedikament",
  "kombinationstherapieMitAnderemMedikamentName",
  "monotherapie",

  // Beschreibung Vortherapie
  "beschreibungVortherapie",
  "bemerkungenZurTherapie",

  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",
];

export interface RemicadeState {
  formData: {

    // Indikation
    aktiveRheumatoideArthritis: boolean,
    morbusCrohn: boolean,
    morbusBechterewAkylosierendeSpondylitis: boolean,
    moderateBisSchwereColitisUlcerosa: boolean,
    schwerePlaquePsoriasis: boolean,
    psoriasisArthritis: boolean,
    andereIndikationSollBehandeltWerden: boolean,

    // vorangegangene Standardtherapie mit DMARDs war unzulänglich
    rheumatoideArthritisPrevNotWorked: boolean,

    // vorangegangene konventionelle Therapie (z.B. Azathioprin, Steroide, 6- Mercaptopurin, etc) war unzulänglichin
    morbusCrohnPrevNotWorked: boolean,

    // Limitatio "Morbus Bechterew

    morbusBechterewPrevNotWorked : boolean,
    facharztFuerRheumatologieInPdfUebernehmen: boolean,
    universitaetsklinikPoliklinik: boolean,

    // "Colitis Ulcerosa
    vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid: boolean,


    // plaque Psoriasis bisherige Therapie(n)
    phototherapieUVBUVA: boolean,
    konventionelleTherapieMitCiclosporin: boolean,
    methotrexat: boolean,
    acitretin: boolean,
    tnfAlphaBlockierendeSubstanz: boolean,
    tnfAlphaBlockierendeSubstanzName: string

    // "Psoriasis Arthritis - Bitte wählen Sie die vorangegangene Therapie an
    kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos: boolean,
    therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos: boolean,
    andereTherapieWarErfolglos: boolean,


    // Bitte geben Sie die vorgesehene Therapievariante an:
    kombinationstherapieMitMethotrexat: boolean,
    kombinationstherapieMitAnderemMedikament: boolean,
    kombinationstherapieMitAnderemMedikamentName: string,
    monotherapie: boolean,

    // Beschreibung Vortherapie
    beschreibungVortherapie: string,
    bemerkungenZurTherapie: string,

    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,
  }
}

const initialState: RemicadeState = {
  formData: {

    // Indikation
    aktiveRheumatoideArthritis: false,
    morbusCrohn: false,
    morbusBechterewAkylosierendeSpondylitis: false,
    moderateBisSchwereColitisUlcerosa: false,
    schwerePlaquePsoriasis: false,
    psoriasisArthritis: false,
    andereIndikationSollBehandeltWerden: false,

    // vorangegangene Standardtherapie mit DMARDs war unzulänglich
    rheumatoideArthritisPrevNotWorked: false,

    // vorangegangene konventionelle Therapie (z.B. Azathioprin, Steroide, 6- Mercaptopurin, etc) war unzulänglichin
    morbusCrohnPrevNotWorked: false,

    // Limitatio "Morbus Bechterew
    morbusBechterewPrevNotWorked: false,
    facharztFuerRheumatologieInPdfUebernehmen: false,
    universitaetsklinikPoliklinik: false,

    // "Colitis Ulcerosa
    vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid: false,

    // plaque Psoriasis bisherige Therapie(n)
    phototherapieUVBUVA: false,
    konventionelleTherapieMitCiclosporin: false,
    methotrexat: false,
    acitretin: false,
    tnfAlphaBlockierendeSubstanz: false,
    tnfAlphaBlockierendeSubstanzName: "",

    // "Psoriasis Arthritis - Bitte wählen Sie die vorangegangene Therapie an
    kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos: false,
    therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos: false,
    andereTherapieWarErfolglos: false,

    // Bitte geben Sie die vorgesehene Therapievariante an:
    kombinationstherapieMitMethotrexat: false,
    kombinationstherapieMitAnderemMedikament: false,
    kombinationstherapieMitAnderemMedikamentName: "",
    monotherapie: false,

    // Beschreibung Vortherapie
    beschreibungVortherapie: "",
    bemerkungenZurTherapie: "",

    // default stuff
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const RemicadeFormSlice = createSlice({
  name: "Remicade",
  initialState,
  reducers: {
    onClearRemicadeState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedRemicadeEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeRemicadeValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeRemicadeValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedRemicadeEditData,
  onClearRemicadeState,
  onChangeRemicadeValue,
  onAddMedication,
  onRemoveMedication,
} = RemicadeFormSlice.actions;

export default RemicadeFormSlice.reducer;
export const selectRemicadeValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.remicadeForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;
  fieldNames.forEach((field) => {
    if (data[field] !== undefined) {
      state.formData[field] = data[field];

      !isEdit && resetBelow(fieldNames, field, data, state);
    }
  });
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
