import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import DrugPlanForm from "./DrugPlanForm";

import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";
import {
  onChangeStelaraValue,
  selectStelaraValues,
} from "./StelaraSlice";


const StelaraForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();
  const StelaraValues = UseAppSelector(selectStelaraValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const handleValueChangeKVV = (field: string, value: any) => dispatch(onChangeTreatmentValue({field, value}));
  const currentLang = i18n.language.toUpperCase();
  const medicineValues = UseAppSelector(selectMedicineValues);
  const [variant, setVariant] = useState(0);

  const isPen1 = medicineValues?.pharmacode === "1103038";
  const isPen2 = medicineValues?.pharmacode === "1103039";
  const isSolution1 = medicineValues?.pharmacode === "4756574";
  const isSolution2 = medicineValues?.pharmacode === "4756580";

  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);

  useEffect(() => {
    setVariant(0);
    if (
      (
        StelaraValues.plaquePsoriasis_Phototherapie ||
        StelaraValues.plaquePsoriasis_Ciclosporin ||
        StelaraValues.plaquePsoriasis_Methotrexat ||
        StelaraValues.plaquePsoriasis_Acitretin
      ) && (
        !StelaraValues.plaquePsoriasis_dermatologySpecialist &&
        !StelaraValues.plaquePsoriasis_universityClinic
      )
    ) {
      setVariant(1);
    }

    if (
      (
        StelaraValues.plaquePsoriasis_Phototherapie ||
        StelaraValues.plaquePsoriasis_Ciclosporin ||
        StelaraValues.plaquePsoriasis_Methotrexat ||
        StelaraValues.plaquePsoriasis_Acitretin
      ) &&
        StelaraValues.plaquePsoriasis_isChildAbove6AndHeavierThan60kg

    ) {
      setVariant(2);
    }
    if (
      (
        StelaraValues.plaquePsoriasis_Phototherapie ||
        StelaraValues.plaquePsoriasis_Ciclosporin ||
        StelaraValues.plaquePsoriasis_Methotrexat ||
        StelaraValues.plaquePsoriasis_Acitretin
      ) &&
        StelaraValues.plaquePsoriasis_isChildAbove6AndLighterThan60kg

    ) {
      setVariant(3);
    }


    if (
      StelaraValues.psoriasisArthritis &&
      (
        !StelaraValues.psoriasisArthritis_dermatologySpecialist &&
        !StelaraValues.psoriasisArthritis_universityClinic)
    ) {
      setVariant(4);
    }

    if (
      StelaraValues.psoriasisArthritis &&
      StelaraValues.psoriasisArthritis_noPriorTherapy &&
      (
        StelaraValues.psoriasisArthritis_dermatologySpecialist ||
        StelaraValues.psoriasisArthritis_universityClinic
      )

    ) {
      setVariant(5);
    }

    if (
      StelaraValues.psoriasisArthritis &&
      StelaraValues.psoriasisArthritis_inadequateResponseToDMARD &&
      (
        StelaraValues.psoriasisArthritis_dermatologySpecialist ||
        StelaraValues.psoriasisArthritis_universityClinic
      ) &&
      StelaraValues.psoriasisArthritis_combinationTherapyWithOtherMedication
    ) {
      setVariant(6);
    }

    if (
      StelaraValues.psoriasisArthritis &&
      StelaraValues.psoriasisArthritis_inadequateResponseToDMARD &&
      (
        StelaraValues.psoriasisArthritis_dermatologySpecialist ||
        StelaraValues.psoriasisArthritis_universityClinic
      ) &&
      StelaraValues.psoriasisArthritis_combinationTherapyWithMTX
    ) {
      setVariant(7);
    }

    if (
      StelaraValues.psoriasisArthritis &&
      StelaraValues.psoriasisArthritis_inadequateResponseToDMARD &&
      (
        StelaraValues.psoriasisArthritis_dermatologySpecialist ||
        StelaraValues.psoriasisArthritis_universityClinic
      ) &&
      StelaraValues.psoriasisArthritis_monotherapy
    ) {
      setVariant(8);
    }

    if (
      StelaraValues.crohnsDisease &&
      StelaraValues.morbusCrohn_erstbehandlungMitStelara &&
      (
        !StelaraValues.morbusCrohn_dermatologySpecialist &&
        !StelaraValues.morbusCrohn_universityClinic
      )

    ) {
      setVariant(9);
    }

    if (
      StelaraValues.crohnsDisease &&
      StelaraValues.morbusCrohn_erstbehandlungMitStelara &&
      (
        StelaraValues.morbusCrohn_dermatologySpecialist ||
        StelaraValues.morbusCrohn_universityClinic
      ) &&
      StelaraValues.morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie

    ) {
      setVariant(10);
    }

    if (
      StelaraValues.crohnsDisease &&
      StelaraValues.morbusCrohn_erstbehandlungMitStelara &&
      (
        StelaraValues.morbusCrohn_dermatologySpecialist ||
        StelaraValues.morbusCrohn_universityClinic
      ) &&
      StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven &&
      StelaraValues.morbusCrohn_monoTherapie

    ) {
      setVariant(11);
    }

    if (
      StelaraValues.crohnsDisease &&
      StelaraValues.morbusCrohn_erstbehandlungMitStelara &&
      (
        StelaraValues.morbusCrohn_dermatologySpecialist ||
        StelaraValues.morbusCrohn_universityClinic
      ) &&
      StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven &&
      StelaraValues.morbusCrohn_kombinationstherapie

    ) {
      setVariant(12);
    }


    if (
      StelaraValues.ulcerativeColitis &&
      StelaraValues.colitisUlcerosa_erstbehandlungMitStelara &&
      (
        !StelaraValues.colitisUlcerosa_dermatologySpecialist &&
        !StelaraValues.colitisUlcerosa_universityClinic
      )

    ) {
      setVariant(13);
    }

    if (
      StelaraValues.ulcerativeColitis &&
      StelaraValues.colitisUlcerosa_erstbehandlungMitStelara &&
      (
        StelaraValues.colitisUlcerosa_dermatologySpecialist ||
        StelaraValues.colitisUlcerosa_universityClinic
      ) &&
      StelaraValues.colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie

    ) {
      setVariant(14);
    }

    if (
      StelaraValues.ulcerativeColitis &&
      StelaraValues.colitisUlcerosa_erstbehandlungMitStelara &&
      (
        StelaraValues.colitisUlcerosa_dermatologySpecialist ||
        StelaraValues.colitisUlcerosa_universityClinic
      ) &&
      StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven &&
      StelaraValues.colitisUlcerosa_monoTherapie
    ) {
      setVariant(15);
    }

    if (
      StelaraValues.ulcerativeColitis &&
      StelaraValues.colitisUlcerosa_erstbehandlungMitStelara &&
      (
        StelaraValues.colitisUlcerosa_dermatologySpecialist ||
        StelaraValues.colitisUlcerosa_universityClinic
      ) &&
      StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven &&
      StelaraValues.colitisUlcerosa_kombinationstherapie
    ) {
      setVariant(16);
    }

    console.debug("variant", variant);
  }, [
    StelaraValues.plaquePsoriasis_Phototherapie,
    StelaraValues.plaquePsoriasis_Ciclosporin,
    StelaraValues.plaquePsoriasis_Methotrexat,
    StelaraValues.plaquePsoriasis_Acitretin,
    StelaraValues.plaquePsoriasis_dermatologySpecialist,
    StelaraValues.plaquePsoriasis_universityClinic,
    StelaraValues.plaquePsoriasis_isChildAbove6AndHeavierThan60kg,
    StelaraValues.plaquePsoriasis_isChildAbove6AndLighterThan60kg,
    StelaraValues.psoriasisArthritis,
    StelaraValues.psoriasisArthritis_dermatologySpecialist,
    StelaraValues.psoriasisArthritis_universityClinic,
    StelaraValues.psoriasisArthritis_noPriorTherapy,
    StelaraValues.psoriasisArthritis_inadequateResponseToDMARD,
    StelaraValues.psoriasisArthritis_combinationTherapyWithOtherMedication,
    StelaraValues.psoriasisArthritis_combinationTherapyWithMTX,
    StelaraValues.psoriasisArthritis_monotherapy,
    StelaraValues.crohnsDisease,
    StelaraValues.morbusCrohn_erstbehandlungMitStelara,
    StelaraValues.morbusCrohn_dermatologySpecialist,
    StelaraValues.morbusCrohn_universityClinic,
    StelaraValues.morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie,
    StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven,
    StelaraValues.morbusCrohn_monoTherapie,
    StelaraValues.morbusCrohn_kombinationstherapie,
    StelaraValues.ulcerativeColitis,
    StelaraValues.colitisUlcerosa_erstbehandlungMitStelara,
    StelaraValues.colitisUlcerosa_dermatologySpecialist,
    StelaraValues.colitisUlcerosa_universityClinic,
    StelaraValues.colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie,
    StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven,
    StelaraValues.colitisUlcerosa_monoTherapie,
    StelaraValues.colitisUlcerosa_kombinationstherapie,

  ]);

  useEffect(() => {
    if (variant === 0) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
    if ( variant === 1) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }
    if (variant === 2) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }
    if (variant === 3) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeDosisValue({field: "dosis", value: t("stelara.dose")}));
    }
    if (variant === 4) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }
    if (variant === 5) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }
    if (variant === 6) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }
    if (variant === 7) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 8) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 9) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 10) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 11) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 12) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 13) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 14) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 15) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }

    if (variant === 16) {
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
    }
  }, [
    variant,
  ]);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} hidden={false}>
          <FormControl>
            <FormLabel>{t("stelara.administration")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-administration"
              name="stelara-radio-buttons-group-administration"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    administrationAsPen: value === "administrationAsPen",
                    administrationAsPreFilledSyringe: value === "administrationAsPreFilledSyringe",
                    administrationAsInfusionSolution: value === "administrationAsInfusionSolution",
                  },
                }));
              }}
            >
              {[
                {value: "administrationAsPen", checked: StelaraValues.administrationAsPen},
                {value: "administrationAsPreFilledSyringe", checked: StelaraValues.administrationAsPreFilledSyringe},
                {value: "administrationAsInfusionSolution", checked: StelaraValues.administrationAsInfusionSolution},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <Grid item xs={12} hidden={
            (
              // isPen
              (StelaraValues.administrationAsPen && isPen1) ||
              (StelaraValues.administrationAsPen && isPen2)
            ) ||
            (
              (StelaraValues.administrationAsPreFilledSyringe && isSolution1) ||
              (StelaraValues.administrationAsPreFilledSyringe && isSolution2)
            ) ||
            (
              StelaraValues.administrationAsInfusionSolution && !isPen1 ||
              StelaraValues.administrationAsInfusionSolution && !isPen2 ||
              StelaraValues.administrationAsInfusionSolution && !isSolution1 ||
              StelaraValues.administrationAsInfusionSolution && !isSolution2
            )


          } >
            <Stack
              direction="row"
              alignItems="left"
              gap={1}
            >
              <WarningIcon color={"primary"}></WarningIcon>
              <Typography>{t("tremfya.warning.pharmacode")}</Typography>
            </Stack>
            <Box sx={{m: 2}} />
          </Grid>


        </Grid>

        <Grid item xs={12} hidden={
          !StelaraValues.administrationAsPen &&
          !StelaraValues.administrationAsPreFilledSyringe &&
          !StelaraValues.administrationAsInfusionSolution
        }>
          <FormControl>
            <FormLabel>{t("stelara.indication")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-indication"
              name="stelara-radio-buttons-group-indication"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    severePlaquePsoriasis: value === "severePlaquePsoriasis",
                    psoriasisArthritis: value === "psoriasisArthritis",
                    crohnsDisease: value === "crohnsDisease",
                    ulcerativeColitis: value === "ulcerativeColitis",
                    otherIndication: value === "otherIndication",
                  },
                }));
              }}
            >
              {[
                {value: "severePlaquePsoriasis", checked: StelaraValues.severePlaquePsoriasis},
                {value: "psoriasisArthritis", checked: StelaraValues.psoriasisArthritis},
                {value: "crohnsDisease", checked: StelaraValues.crohnsDisease},
                {value: "ulcerativeColitis", checked: StelaraValues.ulcerativeColitis},
                {value: "otherIndication", checked: StelaraValues.otherIndication},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.severePlaquePsoriasis}>
          <Typography>{t("stelara.dlqi.explaination")}</Typography>
          <Box sx={{m: 2}} />
        </Grid>

        <Grid item xs={3} hidden={!StelaraValues.severePlaquePsoriasis}>
          <FormControl fullWidth>
            <InputLabel id="stelara-dlqi-label">{t("stelara.dlqi")}</InputLabel>
            <Select
              labelId="stelara-dlqi-label"
              id="stelara-dlqi-select"
              value={
                  StelaraValues.plaquePsoriasis_dlqi_smaller10 ?
                    "plaquePsoriasis_dlqi_smaller10" :
                    StelaraValues.plaquePsoriasis_dlqi_larger10 ?
                      "plaquePsoriasis_dlqi_larger10" :
                      ""
              }
              onChange={(event) => {
                const value = event.target.value;
                dispatch(onChangeStelaraValue({
                  formData: {
                    plaquePsoriasis_dlqi_smaller10: value === "plaquePsoriasis_dlqi_smaller10",
                    plaquePsoriasis_dlqi_larger10: value === "plaquePsoriasis_dlqi_larger10",
                  },
                }));
              }}
            >
              <MenuItem value="plaquePsoriasis_dlqi_smaller10" data-cy="plaquePsoriasis_dlqi_smaller10">
                {t("stelara.plaquePsoriasis_dlqi_smaller10")}
              </MenuItem>
              <MenuItem value="plaquePsoriasis_dlqi_larger10" data-cy="plaquePsoriasis_dlqi_larger10">
                {t("stelara.plaquePsoriasis_dlqi_larger10")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3} hidden={!StelaraValues.severePlaquePsoriasis}>
          <FormControl fullWidth>
            <InputLabel id="stelara-dlqi-label">{t("stelara.dlqi.befall")}</InputLabel>
            <Select
              labelId="stelara-dlqi-label"
              id="stelara-dlqi-select"
              value={
                  StelaraValues.plaquePsoriasis_dlqi_pasi ?
                    "plaquePsoriasis_dlqi_pasi" :
                    StelaraValues.plaquePsoriasis_dlqi_bsa ?
                      "plaquePsoriasis_dlqi_bsa" :
                      StelaraValues.plaquePsoriasis_dlqi_pga ?
                        "plaquePsoriasis_dlqi_pga" :
                        ""
              }
              onChange={(event) => {
                const value = event.target.value;
                dispatch(onChangeStelaraValue({
                  formData: {
                    plaquePsoriasis_dlqi_pasi: value === "plaquePsoriasis_dlqi_pasi",
                    plaquePsoriasis_dlqi_bsa: value === "plaquePsoriasis_dlqi_bsa",
                    plaquePsoriasis_dlqi_pga: value === "plaquePsoriasis_dlqi_pga",
                  },
                }));
              }}
            >
              <MenuItem value="plaquePsoriasis_dlqi_pasi" data-cy="plaquePsoriasis_dlqi_pasi">
                {t("stelara.plaquePsoriasis_dlqi_pasi")}
              </MenuItem>
              <MenuItem value="plaquePsoriasis_dlqi_bsa" data-cy="plaquePsoriasis_dlqi_bsa">
                {t("stelara.plaquePsoriasis_dlqi_bsa")}
              </MenuItem>
              <MenuItem value="plaquePsoriasis_dlqi_pga" data-cy="plaquePsoriasis_dlqi_pga">
                {t("stelara.plaquePsoriasis_dlqi_pga")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3} hidden={!StelaraValues.severePlaquePsoriasis}>
          <TextField
            label={t("stelara.plaquePsoriasis_dlqi_number")}
            variant="outlined"
            fullWidth
            data-cy="plaquePsoriasis_dlqi_number"
            value={StelaraValues.plaquePsoriasis_dlqi_number}
            onChange={(event) => {
              const value = event.target.value;
              if (/^\d*$/.test(value)) { // Allows only digits (0-9)
                dispatch(onChangeStelaraValue({
                  formData: {plaquePsoriasis_dlqi_number: value},
                }));
              }
            }}
            inputProps={{
              inputMode: "numeric", // Optimizes for numeric keyboards on mobile
              pattern: "[0-9]*", // Ensures only numeric input
            }}
          />
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.severePlaquePsoriasis}>
          <FormControl>
            <FormLabel>{t("stelara.manifestationen")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoriasis_befallVonGesicht", checked: StelaraValues.plaquePsoriasis_befallVonGesicht},
                {value: "plaquePsoriasis_befallDerKopfhaut", checked: StelaraValues.plaquePsoriasis_befallDerKopfhaut},
                {value: "plaquePsoriasis_befallDerGenitalien", checked: StelaraValues.plaquePsoriasis_befallDerGenitalien},
                {value: "plaquePsoriasis_befallDerHaenderFingerUndOderNaegel", checked: StelaraValues.plaquePsoriasis_befallDerHaenderFingerUndOderNaegel},
                {value: "plaquePsoriasis_starkerJuckreiz", checked: StelaraValues.plaquePsoriasis_starkerJuckreiz},
                {value: "plaquePsoriasis_hartnaeckigePlaques", checked: StelaraValues.plaquePsoriasis_hartnaeckigePlaques},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(onChangeStelaraValue({
                          formData: {[option.value]: event.target.checked},
                        }))
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.severePlaquePsoriasis}>
          <FormControl>
            <FormLabel>{t("stelara.vorhergehendeTherapienPlaquePsoriasis")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoriasis_Phototherapie", checked: StelaraValues.plaquePsoriasis_Phototherapie},
                {value: "plaquePsoriasis_Ciclosporin", checked: StelaraValues.plaquePsoriasis_Ciclosporin},
                {value: "plaquePsoriasis_Methotrexat", checked: StelaraValues.plaquePsoriasis_Methotrexat},
                {value: "plaquePsoriasis_Acitretin", checked: StelaraValues.plaquePsoriasis_Acitretin},
                {value: "plaquePsoriasis_anderesBiologikum", checked: StelaraValues.plaquePsoriasis_anderesBiologikum},
                {value: "plaquePsoriasis_keineVortherapie", checked: StelaraValues.plaquePsoriasis_keineVortherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(onChangeStelaraValue({
                          formData: {[option.value]: event.target.checked},
                        }))
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !StelaraValues.severePlaquePsoriasis ||
          StelaraValues.plaquePsoriasis_keineVortherapie ||
          (
            !StelaraValues.plaquePsoriasis_Phototherapie &&
            !StelaraValues.plaquePsoriasis_Ciclosporin &&
            !StelaraValues.plaquePsoriasis_Methotrexat &&
            !StelaraValues.plaquePsoriasis_Acitretin &&
            !StelaraValues.plaquePsoriasis_anderesBiologikum
          )
        }>
          <FormControl>
            <FormLabel>{t("stelara.zutreffendenGrund")}</FormLabel>
            <FormGroup>
              {[
                {value: "plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg", checked: StelaraValues.plaquePsoriasis_dieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg},
                {value: "plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen", checked: StelaraValues.plaquePsoriasis_dieBisherigeBehandlungWurdeNichtVertragen},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={(event) =>
                        dispatch(onChangeStelaraValue({
                          formData: {[option.value]: event.target.checked},
                        }))
                      }
                      data-cy={option.value}
                    />
                  }
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.severePlaquePsoriasis}>
          <Typography>{t("stelara.plaquePsoriasis_begruendungDerKontraindikation")}</Typography>
          <Box sx={{m: 1}} />
          <TextField
            style={{width: "100%"}}
            label={t("stelara.justification")}
            variant="outlined"
            fullWidth
            data-cy="plaquePsoriasis_begruendungDerKontraindikation"
            value={StelaraValues.plaquePsoriasis_begruendungDerKontraindikation}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {plaquePsoriasis_begruendungDerKontraindikation: event.target.value},
              }))
            }
          />
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.severePlaquePsoriasis}>
          <Box sx={{m: 2}} />
          <FormLabel>{t("stelara.limitatio")}</FormLabel>
          <FormGroup>
            {[
              {value: "plaquePsoriasis_dermatologySpecialist", checked: StelaraValues.plaquePsoriasis_dermatologySpecialist},
              {value: "plaquePsoriasis_universityClinic", checked: StelaraValues.plaquePsoriasis_universityClinic},
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={(event) =>
                      dispatch(onChangeStelaraValue({
                        formData: {[option.value]: event.target.checked},
                      }))
                    }
                    data-cy={option.value}
                  />
                }
                label={t(`stelara.${option.value}`)}
              />
            ))}
          </FormGroup>
        </Grid>


        <Grid item xs={12} hidden={
          !StelaraValues.severePlaquePsoriasis ||
          !(StelaraValues.plaquePsoriasis_dermatologySpecialist || StelaraValues.plaquePsoriasis_universityClinic)
        }>
          <FormControl>
            <FormLabel>{t("stelara.plaquePsoriasis_child")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-child"
              name="stelara-radio-buttons-group-child"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    plaquePsoriasis_isChildAbove6AndLighterThan60kg: value === "plaquePsoriasis_isChildAbove6AndLighterThan60kg",
                    plaquePsoriasis_isChildAbove6AndHeavierThan60kg: value === "plaquePsoriasis_isChildAbove6AndHeavierThan60kg",
                  },
                }));
              }}
            >
              {[
                {value: "plaquePsoriasis_isChildAbove6AndLighterThan60kg", checked: StelaraValues.plaquePsoriasis_isChildAbove6AndLighterThan60kg},
                {value: "plaquePsoriasis_isChildAbove6AndHeavierThan60kg", checked: StelaraValues.plaquePsoriasis_isChildAbove6AndHeavierThan60kg},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {
          /*


          Psoriasis Arthritis


          */
        }
        <Grid item xs={12} hidden={!StelaraValues.psoriasisArthritis}>
          <FormControl>
            <FormLabel>{t("stelara.psoriasisArthritis_limitations")}</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={StelaraValues.psoriasisArthritis_dermatologySpecialist}
                    onChange={(event) =>
                      dispatch(onChangeStelaraValue({
                        formData: {psoriasisArthritis_dermatologySpecialist: event.target.checked},
                      }))
                    }
                  />
                }
                label={t("stelara.psoriasisArthritis_dermatologySpecialist")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={StelaraValues.psoriasisArthritis_universityClinic}
                    onChange={(event) =>
                      dispatch(onChangeStelaraValue({
                        formData: {psoriasisArthritis_universityClinic: event.target.checked},
                      }))
                    }
                  />
                }
                label={t("stelara.psoriasisArthritis_universityClinic")}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.psoriasisArthritis}>
          <FormControl>
            <FormLabel>{t("stelara.vortherapie")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-vortherapie"
              name="stelara-radio-buttons-group-vortherapie"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    psoriasisArthritis_noPriorTherapy: value === "psoriasisArthritis_noPriorTherapy",
                    psoriasisArthritis_inadequateResponseToDMARD: value === "psoriasisArthritis_inadequateResponseToDMARD",
                  },
                }));
              }}
            >
              {[
                {value: "psoriasisArthritis_noPriorTherapy", checked: StelaraValues.psoriasisArthritis_noPriorTherapy},
                {value: "psoriasisArthritis_inadequateResponseToDMARD", checked: StelaraValues.psoriasisArthritis_inadequateResponseToDMARD},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={
          !StelaraValues.psoriasisArthritis ||
          !StelaraValues.psoriasisArthritis_noPriorTherapy
        }>
          <Typography>
            {t("stelara.psoriasisArthritis_contraindication.explaination")}
          </Typography>
          <TextField
            label={t("stelara.justification")}
            variant="outlined"
            fullWidth
            value={StelaraValues.psoriasisArthritis_contraindication}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {psoriasisArthritis_contraindication: event.target.value},
              }))
            }
          />
        </Grid>

        <Grid item xs={12} hidden={
          !StelaraValues.psoriasisArthritis ||
          !StelaraValues.psoriasisArthritis_inadequateResponseToDMARD

        }>
          <FormControl>
            <FormLabel>{t("stelara.psoriasisArthritis_therapy")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-therapy"
              name="stelara-radio-buttons-group-therapy"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    psoriasisArthritis_monotherapy: value === "psoriasisArthritis_monotherapy",
                    psoriasisArthritis_combinationTherapyWithMTX: value === "psoriasisArthritis_combinationTherapyWithMTX",
                    psoriasisArthritis_combinationTherapyWithOtherMedication: value === "psoriasisArthritis_combinationTherapyWithOtherMedication",
                  },
                }));
              }}
            >
              {[
                {value: "psoriasisArthritis_monotherapy", checked: StelaraValues.psoriasisArthritis_monotherapy},
                {value: "psoriasisArthritis_combinationTherapyWithMTX", checked: StelaraValues.psoriasisArthritis_combinationTherapyWithMTX},
                {value: "psoriasisArthritis_combinationTherapyWithOtherMedication", checked: StelaraValues.psoriasisArthritis_combinationTherapyWithOtherMedication},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
            <Box sx={{m: 1}}/>
            <Typography hidden={!StelaraValues.psoriasisArthritis_combinationTherapyWithOtherMedication}>
              {t("stelara.psoriasisArthritis_medicationForCombinationTherapy.explaination")}
            </Typography>
            <DrugPlanForm
              hidden={
                !StelaraValues.psoriasisArthritis ||
                !StelaraValues.psoriasisArthritis_combinationTherapyWithOtherMedication
              }
              label={t("stelara.psoriasisArthritis_medicationForCombinationTherapy")}
              data-cy="psoriasisArthritisKombinationstherapieOtherMedication"
              onChange={(newValue) =>
                dispatch(onChangeStelaraValue({formData: {psoriasisArthritis_medicationForCombinationTherapy: newValue}}))}
              value={StelaraValues.psoriasisArthritis_medicationForCombinationTherapy || ""}
            />

          </FormControl>
        </Grid>
        {

          /*


          Crohns Disease


          */

        }
        <Grid item xs={12} hidden={!StelaraValues.crohnsDisease}>
          <FormControl>
            <FormLabel>{t("stelara.morbusCrohn_limitatio")}</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={StelaraValues.morbusCrohn_dermatologySpecialist}
                  onChange={(event) =>
                    dispatch(onChangeStelaraValue({
                      formData: {morbusCrohn_dermatologySpecialist: event.target.checked},
                    }))
                  }
                />
              }
              label={t("stelara.morbusCrohn_dermatologySpecialist")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={StelaraValues.morbusCrohn_universityClinic}
                  onChange={(event) =>
                    dispatch(onChangeStelaraValue({
                      formData: {morbusCrohn_universityClinic: event.target.checked},
                    }))
                  }
                />
              }
              label={t("stelara.morbusCrohn_universityClinic")}
            />
          </FormControl>
        </Grid>


        <Grid item xs={12} hidden={

          !StelaraValues.crohnsDisease

        }>
          <FormControl>
            <FormLabel>{t("stelara.morbusCrohn_behandlungsvorhaben")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-morbusCrohn-behandlungsvorhaben"
              name="stelara-radio-buttons-group-morbusCrohn-behandlungsvorhaben"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    morbusCrohn_erstbehandlungMitStelara: value === "morbusCrohn_erstbehandlungMitStelara",
                    morbusCrohn_verkuerzungDesDosierungsintervalls: value === "morbusCrohn_verkuerzungDesDosierungsintervalls",
                    morbusCrohn_weiterbehandlung: value === "morbusCrohn_weiterbehandlung",
                  },
                }));
              }}
            >
              {[
                {value: "morbusCrohn_erstbehandlungMitStelara", checked: StelaraValues.morbusCrohn_erstbehandlungMitStelara},
                {value: "morbusCrohn_verkuerzungDesDosierungsintervalls", checked: StelaraValues.morbusCrohn_verkuerzungDesDosierungsintervalls},
                {value: "morbusCrohn_weiterbehandlung", checked: StelaraValues.morbusCrohn_weiterbehandlung},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.crohnsDisease}>
          <Box sx={{m: 1}}/>
          <TextField
            hidden={!StelaraValues.morbusCrohn_erstbehandlungMitStelara}
            style={{width: "100%"}}
            label={t("stelara.morbusCrohn_BeschreibungVorbehandlung")}
            variant="outlined"
            fullWidth
            value={StelaraValues.morbusCrohn_BeschreibungVorbehandlung}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {morbusCrohn_BeschreibungVorbehandlung: event.target.value},
              }))
            }
          />
          <Box sx={{m: 1}}/>
          <TextField
            hidden={!StelaraValues.morbusCrohn_weiterbehandlung}
            style={{width: "100%"}}
            label={t("stelara.morbusCrohn_BeschreibungTherapie")}
            variant="outlined"
            fullWidth
            value={StelaraValues.morbusCrohn_BeschreibungTherapie}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {morbusCrohn_BeschreibungTherapie: event.target.value},
              }))
            }
          />
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.crohnsDisease}>
          <FormControl>
            <FormLabel
              hidden = {
                !(
                  StelaraValues.morbusCrohn_erstbehandlungMitStelara ||
                              StelaraValues.morbusCrohn_verkuerzungDesDosierungsintervalls ||
                              StelaraValues.morbusCrohn_weiterbehandlung
                )
              }
            >{t("stelara.morbusCrohn_behandlung")}</FormLabel>
            <Box sx={{m: 1}}
              hidden = {
                !(
                  StelaraValues.morbusCrohn_erstbehandlungMitStelara ||
                                          StelaraValues.morbusCrohn_verkuerzungDesDosierungsintervalls ||
                                          StelaraValues.morbusCrohn_weiterbehandlung
                )
              }
            />
            <RadioGroup
              hidden = {
                !(
                  StelaraValues.morbusCrohn_erstbehandlungMitStelara ||
                  StelaraValues.morbusCrohn_verkuerzungDesDosierungsintervalls ||
                  StelaraValues.morbusCrohn_weiterbehandlung
                )
              }
              aria-labelledby="stelara-radio-buttons-group-morbusCrohn"
              name="stelara-radio-buttons-group-morbusCrohn"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    morbusCrohn_behandlungMittelschweremBisSchweremAktiven: value === "morbusCrohn_behandlungMittelschweremBisSchweremAktiven",
                    morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: value === "morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",
                  },
                }));
              }}
            >
              {[
                {value: "morbusCrohn_behandlungMittelschweremBisSchweremAktiven", checked: StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven},
                {value: "morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie", checked: StelaraValues.morbusCrohn_behandlungErwachsenerPatientenOhneVorgaengigeTherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
            <Box sx={{m: 1}}
              hidden = {
                !(
                  StelaraValues.morbusCrohn_erstbehandlungMitStelara ||
                  StelaraValues.morbusCrohn_verkuerzungDesDosierungsintervalls ||
                  StelaraValues.morbusCrohn_weiterbehandlung
                )
              }/>
            <Typography
              hidden = {!StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven}>
              {t("stelara.morbusCrohn_behandlungMittelschweremBisSchweremAktiven.explaination")}
            </Typography>
            <RadioGroup
              hidden = {!StelaraValues.morbusCrohn_behandlungMittelschweremBisSchweremAktiven}
              aria-labelledby="stelara-radio-buttons-group-morbusCrohn-therapy"
              name="stelara-radio-buttons-group-morbusCrohn-therapy"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    morbusCrohn_monoTherapie: value === "morbusCrohn_monoTherapie",
                    morbusCrohn_kombinationstherapie: value === "morbusCrohn_kombinationstherapie",
                  },
                }));
              }}
            >
              {[
                {value: "morbusCrohn_monoTherapie", checked: StelaraValues.morbusCrohn_monoTherapie},
                {value: "morbusCrohn_kombinationstherapie", checked: StelaraValues.morbusCrohn_kombinationstherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} hidden={!StelaraValues.crohnsDisease}>
          <Typography
            hidden = {!StelaraValues.morbusCrohn_kombinationstherapie}>
            {t("stelara.morbusCrohn_kombinationstherapie.explaination")}
          </Typography>
          <DrugPlanForm
            hidden={!StelaraValues.morbusCrohn_kombinationstherapie}
            label={t("stelara.morbusCrohn_kombinationstherapieMedikament")}
            data-cy="morbusCrohn_kombinationstherapieMedikament"
            onChange={(newValue) =>
              dispatch(onChangeStelaraValue({formData: {morbusCrohn_kombinationstherapieMedikament: newValue}}))}
            value={StelaraValues.morbusCrohn_kombinationstherapieMedikament || ""}
          />


          <Box sx={{m: 2}}/>
        </Grid>
        {
          /*


          Ulcerative Colitis


          */

        }

        <Grid item xs={12} hidden={!StelaraValues.ulcerativeColitis}>
          <FormControl>
            <FormLabel>{t("stelara.colitisUlcerosa_limitation")}</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={StelaraValues.colitisUlcerosa_dermatologySpecialist}
                    onChange={(event) =>
                      dispatch(onChangeStelaraValue({
                        formData: {colitisUlcerosa_dermatologySpecialist: event.target.checked},
                      }))
                    }
                  />
                }
                label={t("stelara.colitisUlcerosa_dermatologySpecialist")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={StelaraValues.colitisUlcerosa_universityClinic}
                    onChange={(event) =>
                      dispatch(onChangeStelaraValue({
                        formData: {colitisUlcerosa_universityClinic: event.target.checked},
                      }))
                    }
                  />
                }
                label={t("stelara.colitisUlcerosa_universityClinic")}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} hidden={
          !StelaraValues.ulcerativeColitis
        }>
          <FormControl>
            <FormLabel>{t("stelara.colitisUlcerosa_behandlungsvorhaben")}</FormLabel>
            <RadioGroup
              aria-labelledby="stelara-radio-buttons-group-colitisUlcerosa-behandlungsvorhaben"
              name="stelara-radio-buttons-group-colitisUlcerosa-behandlungsvorhaben"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    colitisUlcerosa_erstbehandlungMitStelara: value === "colitisUlcerosa_erstbehandlungMitStelara",
                    colitisUlcerosa_verkuerzungDesDosierungsintervalls: value === "colitisUlcerosa_verkuerzungDesDosierungsintervalls",
                    colitisUlcerosa_weiterbehandlung: value === "colitisUlcerosa_weiterbehandlung",
                  },
                }));
              }}
            >
              {[
                {value: "colitisUlcerosa_erstbehandlungMitStelara", checked: StelaraValues.colitisUlcerosa_erstbehandlungMitStelara},
                {value: "colitisUlcerosa_verkuerzungDesDosierungsintervalls", checked: StelaraValues.colitisUlcerosa_verkuerzungDesDosierungsintervalls},
                {value: "colitisUlcerosa_weiterbehandlung", checked: StelaraValues.colitisUlcerosa_weiterbehandlung},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} hidden={!StelaraValues.ulcerativeColitis}>
          <TextField
            hidden = {!StelaraValues.colitisUlcerosa_erstbehandlungMitStelara}
            label={t("stelara.colitisUlcerosa_BeschreibungVorbehandlung")}
            variant="outlined"
            fullWidth
            value={StelaraValues.colitisUlcerosa_BeschreibungVorbehandlung}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {colitisUlcerosa_BeschreibungVorbehandlung: event.target.value},
              }))
            }
          />
          <TextField
            hidden = {!StelaraValues.colitisUlcerosa_weiterbehandlung}
            label={t("stelara.colitisUlcerosa_BeschreibungTherapie")}
            variant="outlined"
            fullWidth
            value={StelaraValues.colitisUlcerosa_BeschreibungTherapie}
            onChange={(event) =>
              dispatch(onChangeStelaraValue({
                formData: {colitisUlcerosa_BeschreibungTherapie: event.target.value},
              }))
            }
          />
        </Grid>
        <Grid item xs={12} hidden={!StelaraValues.ulcerativeColitis}>
          <FormControl>
            <RadioGroup
              hidden = {
                !(
                  StelaraValues.colitisUlcerosa_erstbehandlungMitStelara ||
                  StelaraValues.colitisUlcerosa_verkuerzungDesDosierungsintervalls ||
                  StelaraValues.colitisUlcerosa_weiterbehandlung
                )
              }
              aria-labelledby="stelara-radio-buttons-group-colitisUlcerosa"
              name="stelara-radio-buttons-group-colitisUlcerosa"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven: value === "colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven",
                    colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie: value === "colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie",
                  },
                }));
              }}
            >
              {[
                {value: "colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven", checked: StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven},
                {value: "colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie", checked: StelaraValues.colitisUlcerosa_behandlungErwachsenerPatientenOhneVorgaengigeTherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
            <Box sx={{m: 1}}></Box>
            <Typography hidden={!StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven}>
              {t("stelara.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven.explaination")}
            </Typography>
            <RadioGroup
              hidden = {
                !(
                  StelaraValues.colitisUlcerosa_behandlungMittelschweremBisSchweremAktiven
                )
              }
              aria-labelledby="stelara-radio-buttons-group-colitisUlcerosa-therapy"
              name="stelara-radio-buttons-group-colitisUlcerosa-therapy"
              onChange={(event, value) => {
                dispatch(onChangeStelaraValue({
                  formData: {
                    colitisUlcerosa_monoTherapie: value === "colitisUlcerosa_monoTherapie",
                    colitisUlcerosa_kombinationstherapie: value === "colitisUlcerosa_kombinationstherapie",
                  },
                }));
              }}
            >
              {[
                {value: "colitisUlcerosa_monoTherapie", checked: StelaraValues.colitisUlcerosa_monoTherapie},
                {value: "colitisUlcerosa_kombinationstherapie", checked: StelaraValues.colitisUlcerosa_kombinationstherapie},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`stelara.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12} hidden={!StelaraValues.ulcerativeColitis}>

          <DrugPlanForm
            hidden={!StelaraValues.colitisUlcerosa_kombinationstherapie}
            label={t("stelara.colitisUlcerosa_kombinationstherapieMedikament")}
            data-cy="colitisUlcerosa_kombinationstherapieMedikament"
            onChange={(newValue) =>
              dispatch(onChangeStelaraValue({formData: {colitisUlcerosa_kombinationstherapieMedikament: newValue}}))}
            value={StelaraValues.colitisUlcerosa_kombinationstherapieMedikament || ""}
          />


          <Box sx={{m: 2}}/>
        </Grid>


        {
          /* Other Indication */
        }


        <Grid
          hidden={
            !StelaraValues.otherIndication
          }
          item xs={12}>
          <Typography>{t("tremfya.indication_other.title")}</Typography>
          <Box sx={{m: 3}} />
          {[
            {label: "preTreatment", dataCy: "preTreatment", value: StelaraValues.preTreatment},
            {label: "patientCondition", dataCy: "patientCondition", value: StelaraValues.patientCondition},
            {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: StelaraValues.reasonsAlternativeTherapiesFailed},
            {label: "prognosis", dataCy: "prognosis", value: StelaraValues.prognosis},
          ].map((field, index) => (
            <React.Fragment key={field.label}>
              <TextField
                style={{width: "100%"}}
                label={t(field.label)}
                data-cy={field.dataCy}
                InputLabelProps={{shrink: true}}
                value={field.value}
                variant="outlined"
                maxRows={4}
                minRows={4}
                multiline
                onChange={(event) =>
                  dispatch(
                      onChangeStelaraValue({
                        [field.label]: event.target.value,
                      }),
                  )
                }
              />
              <Box sx={{m: 3}} />
            </React.Fragment>
          ))}
        </Grid>
        {variant > 0 && <Grid item xs={12}>
          <Typography
            data-cy="variant"
          >{t("tremfya.variant")} {variant}</Typography>
        </Grid>}
      </Grid>
    </LocalizationProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(StelaraForm);
