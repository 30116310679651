import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [

  // Verabreichungs Options
  "administrationAsPen",
  "administrationAsSyringe",

  // Indication Options
  "rheumatoidArthritis",
  "psoriaticArthritis",
  "morbusBechterew",
  "ulcerativeColitis",
  "otherIndication",

  // List why previous therapies were insufficient
  "nsaidsTherapyInsufficient",
  "dmardsTherapyInsufficient",

  /*
    * Rheumatoid Arthritis
    */
  // Previous Therapies checkboxes
  "rheumatoidArthritisMethotrexat",
  "rheumatoidArthritisLeflunomid",
  "rheumatoidArthritisSulfasalazin",
  "rheumatoidArthritisAndereBiologikum",
  "therapieRemarks",

  // Therapievariante options
  "arthritisCombinationTherapyWithMethotrexate",
  "arthritisCombinationTherapyWithOtherMedication",
  "arthritisMonotherapy",
  "arthritisCombinationTherapyMedication",

  /*
    * Colitis Ulcerosa
    */
  "ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated",

  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",

];

export interface SimponiState {
  formData: {

    // Verabreichungs Options
    administrationAsPen: boolean,
    administrationAsSyringe: boolean,

    // Indication Options
    rheumatoidArthritis: boolean,
    psoriaticArthritis: boolean,
    morbusBechterew: boolean,
    ulcerativeColitis: boolean,
    otherIndication: boolean,

    // List why previous therapies were insufficient
    nsaidsTherapyInsufficient: boolean,
    dmardsTherapyInsufficient: boolean,


    /*
    * Rheumatoid Arthritis
    */
    // Previeous Therapies checkboxes
    rheumatoidArthritisMethotrexat: boolean,
    rheumatoidArthritisLeflunomid: boolean,
    rheumatoidArthritisSulfasalazin: boolean,
    rheumatoidArthritisAndereBiologikum: boolean,
    therapieRemarks: string,

    // Therapievariante options
    arthritisCombinationTherapyWithMethotrexate: boolean,
    arthritisCombinationTherapyWithOtherMedication: boolean,
    arthritisMonotherapy: boolean,
    arthritisCombinationTherapyMedication: string

    /*
    * Colitis Ulcerosa
    */
    ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated: boolean,


    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,
  }
}

const initialState: SimponiState = {
  formData: {

    // Verabreichungs Options
    administrationAsPen: false,
    administrationAsSyringe: false,

    // Indication Options
    rheumatoidArthritis: false,
    psoriaticArthritis: false,
    morbusBechterew: false,
    ulcerativeColitis: false,
    otherIndication: false,

    // List why previous therapies were insufficient
    nsaidsTherapyInsufficient: false,
    dmardsTherapyInsufficient: false,


    /*
    * Rheumatoid Arthritis
    */
    // Previous Therapies checkboxes
    rheumatoidArthritisMethotrexat: false,
    rheumatoidArthritisLeflunomid: false,
    rheumatoidArthritisSulfasalazin: false,
    rheumatoidArthritisAndereBiologikum: false,
    therapieRemarks: "",

    // Therapievariante options
    arthritisCombinationTherapyWithMethotrexate: false,
    arthritisCombinationTherapyWithOtherMedication: false,
    arthritisMonotherapy: false,
    arthritisCombinationTherapyMedication: "",

    /*
    * Colitis Ulcerosa
    */
    ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated: false,


    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const SimponiFormSlice = createSlice({
  name: "Simponi",
  initialState,
  reducers: {
    onClearSimponiState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedSimponiEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
        state.formData.therapieRemarks =
          payload.formData?.therapieRemarks;
        state.formData.arthritisCombinationTherapyMedication =
          payload.formData?.arthritisCombinationTherapyMedication;
      }
    },
    onChangeSimponiValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeSimponiValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;

      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedSimponiEditData,
  onClearSimponiState,
  onChangeSimponiValue,
  onAddMedication,
  onRemoveMedication,
} = SimponiFormSlice.actions;

export default SimponiFormSlice.reducer;
export const selectSimponiValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.simponiForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("updatestate", JSON.stringify(data));
  if (!data) return;

  // Update specific fields only if they are provided in data
  if ("administrationAsPen" in data) {
    state.formData.administrationAsPen = data.administrationAsPen;
    !isEdit && resetBelow(fieldNames, "administrationAsPen", data, state);
  }
  if ("administrationAsSyringe" in data) {
    state.formData.administrationAsSyringe = data.administrationAsSyringe;
    !isEdit && resetBelow(fieldNames, "administrationAsSyringe", data, state);
  }
  if ("rheumatoidArthritis" in data) {
    state.formData.rheumatoidArthritis = data.rheumatoidArthritis;
    !isEdit && resetBelow(fieldNames, "rheumatoidArthritis", data, state);
  }
  if ("psoriaticArthritis" in data) {
    state.formData.psoriaticArthritis = data.psoriaticArthritis;
    !isEdit && resetBelow(fieldNames, "psoriaticArthritis", data, state);
  }
  if ("morbusBechterew" in data) {
    state.formData.morbusBechterew = data.morbusBechterew;
    !isEdit && resetBelow(fieldNames, "morbusBechterew", data, state);
  }
  if ("ulcerativeColitis" in data) {
    state.formData.ulcerativeColitis = data.ulcerativeColitis;
    !isEdit && resetBelow(fieldNames, "ulcerativeColitis", data, state);
  }
  if ("otherIndication" in data) {
    state.formData.otherIndication = data.otherIndication;
    !isEdit && resetBelow(fieldNames, "otherIndication", data, state);
  }
  if ("nsaidsTherapyInsufficient" in data) {
    state.formData.nsaidsTherapyInsufficient = data.nsaidsTherapyInsufficient;
    !isEdit && resetBelow(fieldNames, "nsaidsTherapyInsufficient", data, state);
  }
  if ("dmardsTherapyInsufficient" in data) {
    state.formData.dmardsTherapyInsufficient = data.dmardsTherapyInsufficient;
    !isEdit && resetBelow(fieldNames, "dmardsTherapyInsufficient", data, state);
  }
  if ("rheumatoidArthritisMethotrexat" in data) {
    state.formData.rheumatoidArthritisMethotrexat = data.rheumatoidArthritisMethotrexat;
    !isEdit && resetBelow(fieldNames, "rheumatoidArthritisMethotrexat", data, state);
  }
  if ("rheumatoidArthritisLeflunomid" in data) {
    state.formData.rheumatoidArthritisLeflunomid = data.rheumatoidArthritisLeflunomid;
    !isEdit && resetBelow(fieldNames, "rheumatoidArthritisLeflunomid", data, state);
  }
  if ("rheumatoidArthritisSulfasalazin" in data) {
    state.formData.rheumatoidArthritisSulfasalazin = data.rheumatoidArthritisSulfasalazin;
    !isEdit && resetBelow(fieldNames, "rheumatoidArthritisSulfasalazin", data, state);
  }
  if ("rheumatoidArthritisAndereBiologikum" in data) {
    state.formData.rheumatoidArthritisAndereBiologikum = data.rheumatoidArthritisAndereBiologikum;
    !isEdit && resetBelow(fieldNames, "rheumatoidArthritisAndereBiologikum", data, state);
  }
  if ("therapieRemarks" in data) {
    state.formData.therapieRemarks = data.therapieRemarks;
    !isEdit && resetBelow(fieldNames, "therapieRemarks", data, state);
  }
  if ("arthritisCombinationTherapyWithMethotrexate" in data) {
    state.formData.arthritisCombinationTherapyWithMethotrexate =
    data.arthritisCombinationTherapyWithMethotrexate;
    !isEdit && resetBelow(fieldNames, "arthritisCombinationTherapyWithMethotrexate", data, state);
  }
  if ("arthritisCombinationTherapyWithOtherMedication" in data) {
    state.formData.arthritisCombinationTherapyWithOtherMedication =
    data.arthritisCombinationTherapyWithOtherMedication;
    !isEdit && resetBelow(fieldNames, "arthritisCombinationTherapyWithOtherMedication", data, state);
  }
  if ("arthritisMonotherapy" in data) {
    state.formData.arthritisMonotherapy = data.arthritisMonotherapy;
    !isEdit && resetBelow(fieldNames, "arthritisMonotherapy", data, state);
  }
  if ("arthritisCombinationTherapyMedication" in data) {
    state.formData.arthritisCombinationTherapyMedication = data.arthritisCombinationTherapyMedication;
    !isEdit && resetBelow(fieldNames, "arthritisCombinationTherapyMedication", data, state);
  }
  if ("ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated" in data) {
    state.formData.ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated =
    data.ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated;
    !isEdit && resetBelow(fieldNames, "ulcerativeColitisPreviousConventionalTherapyInsufficientOrNotTolerated", data, state);
  }
  if ("preTreatment" in data) {
    state.formData.preTreatment = data.preTreatment;
    !isEdit && resetBelow(fieldNames, "preTreatment", data, state);
  }
  if ("patientCondition" in data) {
    state.formData.patientCondition = data.patientCondition;
    !isEdit && resetBelow(fieldNames, "patientCondition", data, state);
  }
  if ("prognosis" in data) {
    state.formData.prognosis = data.prognosis;
    !isEdit && resetBelow(fieldNames, "prognosis", data, state);
  }
  if ("reasonsAlternativeTherapiesFailed" in data) {
    state.formData.reasonsAlternativeTherapiesFailed = data.reasonsAlternativeTherapiesFailed;
    !isEdit && resetBelow(fieldNames, "reasonsAlternativeTherapiesFailed", data, state);
  }

  if (isEdit) {
    resetBelow(fieldNames, "", data, state);
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
