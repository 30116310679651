import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import DrugPlanForm from "./DrugPlanForm";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";

import {
  onChangeRemicadeValue,
  selectRemicadeValues,
} from "./RemicadeSlice";

const RemicadeForm = (props) => {
  // usestate for variant, numeric value:
  const [variant, setVariant] = useState(0);

  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const RemicadeValues = UseAppSelector(selectRemicadeValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);


  useEffect(() => {
    console.debug("RemicadeValues changed", RemicadeValues);
    setVariant(0);
    if (
      RemicadeValues.aktiveRheumatoideArthritis &&
      RemicadeValues.rheumatoideArthritisPrevNotWorked
    ) {
      setVariant(1);
    }

    if (
      RemicadeValues.morbusCrohn &&
      RemicadeValues.morbusCrohnPrevNotWorked
    ) {
      setVariant(2);
    }
    if (
      RemicadeValues.morbusBechterewAkylosierendeSpondylitis &&
      RemicadeValues.morbusBechterewPrevNotWorked &&
      (RemicadeValues.facharztFuerRheumatologieInPdfUebernehmen ||
        RemicadeValues.universitaetsklinikPoliklinik)
    ) {
      setVariant(3);
    }
    if (
      RemicadeValues.morbusBechterewAkylosierendeSpondylitis &&
      RemicadeValues.morbusBechterewPrevNotWorked &&
      (!RemicadeValues.facharztFuerRheumatologieInPdfUebernehmen &&
        !RemicadeValues.universitaetsklinikPoliklinik)
    ) {
      setVariant(4);
    }

    if (
      RemicadeValues.moderateBisSchwereColitisUlcerosa &&
      RemicadeValues.vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid
    ) {
      setVariant(5);
    }

    if (
      RemicadeValues.schwerePlaquePsoriasis &&
      RemicadeValues.vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid
    ) {
      setVariant(6);
    }
    if (
      RemicadeValues.psoriasisArthritis
    ) {
      if (
        RemicadeValues.kombinationstherapieMitAnderemMedikament ||
        RemicadeValues.monotherapie ||
        (RemicadeValues.andereTherapieWarErfolglos && RemicadeValues.beschreibungVortherapie)

      ) {
        setVariant(8);
      }
      if (
        RemicadeValues.kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos ||
      (RemicadeValues.therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos &&
        RemicadeValues.kombinationstherapieMitMethotrexat)

      ) {
        setVariant(7);
      }
    }
  }, [
    RemicadeValues.aktiveRheumatoideArthritis,
    RemicadeValues.morbusCrohn,
    RemicadeValues.morbusBechterewAkylosierendeSpondylitis,
    RemicadeValues.moderateBisSchwereColitisUlcerosa,
    RemicadeValues.schwerePlaquePsoriasis,
    RemicadeValues.psoriasisArthritis,
    RemicadeValues.andereIndikationSollBehandeltWerden,
    RemicadeValues.rheumatoideArthritisPrevNotWorked,
    RemicadeValues.morbusCrohnPrevNotWorked,
    RemicadeValues.morbusBechterewPrevNotWorked,
    RemicadeValues.facharztFuerRheumatologieInPdfUebernehmen,
    RemicadeValues.universitaetsklinikPoliklinik,
    RemicadeValues.vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid,
    RemicadeValues.phototherapieUVBUVA,
    RemicadeValues.konventionelleTherapieMitCiclosporin,
    RemicadeValues.methotrexat,
    RemicadeValues.acitretin,
    RemicadeValues.tnfAlphaBlockierendeSubstanz,
    RemicadeValues.tnfAlphaBlockierendeSubstanzName,
    RemicadeValues.kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos,
    RemicadeValues.therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos,
    RemicadeValues.andereTherapieWarErfolglos,
    RemicadeValues.kombinationstherapieMitMethotrexat,
    RemicadeValues.kombinationstherapieMitAnderemMedikament,
    RemicadeValues.monotherapie,
    RemicadeValues.kombinationstherapieMitAnderemMedikamentName,
    RemicadeValues.beschreibungVortherapie,
    RemicadeValues.bemerkungenZurTherapie,
    RemicadeValues.preTreatment,
    RemicadeValues.patientCondition,
    RemicadeValues.reasonsAlternativeTherapiesFailed,
    RemicadeValues.prognosis,
  ]);


  useEffect(() => {
    console.debug("RemicadeValues changed", RemicadeValues);
    switch (variant) {
      case 1:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 2:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 3:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 4:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 5:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 6:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 7:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      case 8:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeDosisValue({field: "dosis", value: i18n.t("remicade.dosis.label.fi")}));
        break;
      default:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeDosisValue({field: "dosis", value: ""}));
        break;
    }
  }, [
    variant,
  ]);


  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item container xs={12} spacing={2}>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("remicade.indikation")}</FormLabel>
            <RadioGroup
              aria-labelledby="remicade-radio-buttons-group-indications"
              name="remicade-radio-buttons-group-indications"
              value={Object.keys(RemicadeValues).find((key) => RemicadeValues[key] === true) || ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const updatedValues = {
                  aktiveRheumatoideArthritis: false,
                  morbusCrohn: false,
                  morbusBechterewAkylosierendeSpondylitis: false,
                  moderateBisSchwereColitisUlcerosa: false,
                  schwerePlaquePsoriasis: false,
                  psoriasisArthritis: false,
                  andereIndikationSollBehandeltWerden: false,
                  [selectedValue]: true,
                };
                dispatch(onChangeRemicadeValue({formData: updatedValues}));
              }}
            >
              {[
                {label: "aktiveRheumatoideArthritis", value: RemicadeValues.aktiveRheumatoideArthritis},
                {label: "morbusCrohn", value: RemicadeValues.morbusCrohn},
                {label: "morbusBechterewAkylosierendeSpondylitis", value: RemicadeValues.morbusBechterewAkylosierendeSpondylitis},
                {label: "moderateBisSchwereColitisUlcerosa", value: RemicadeValues.moderateBisSchwereColitisUlcerosa},
                {label: "schwerePlaquePsoriasis", value: RemicadeValues.schwerePlaquePsoriasis},
                {label: "psoriasisArthritis", value: RemicadeValues.psoriasisArthritis},
                {label: "andereIndikationSollBehandeltWerden", value: RemicadeValues.andereIndikationSollBehandeltWerden},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  value={field.label}
                  control={<Radio checked={field.value} data-cy={field.label} />}
                  label={t(`remicade.${field.label}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.aktiveRheumatoideArthritis}
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={RemicadeValues.rheumatoideArthritisPrevNotWorked}
                    onChange={(event) =>
                      dispatch(
                          onChangeRemicadeValue({formData: {
                            rheumatoideArthritisPrevNotWorked: event.target.checked,
                          }}),
                      )
                    }
                  />
                }
                label={t("remicade.rheumatoideArthritisPrevNotWorked")}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.morbusCrohn}
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={RemicadeValues.morbusCrohnPrevNotWorked}
                    onChange={(event) =>
                      dispatch(
                          onChangeRemicadeValue({formData: {
                            morbusCrohnPrevNotWorked: event.target.checked,
                          }}),
                      )
                    }
                  />
                }
                label={t("remicade.morbusCrohnPrevNotWorked")}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.morbusBechterewAkylosierendeSpondylitis}

        >
          <Box sx={{m: 3}} />
          <FormControl component="fieldset">
            <FormGroup>
              {[
                {label: "morbusBechterewPrevNotWorked", value: RemicadeValues.morbusBechterewPrevNotWorked},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeRemicadeValue({formData: {
                              [field.label]: event.target.checked,
                            }}),
                        )
                      }
                    />
                  }
                  label={t(`remicade.${field.label}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("remicade.limitatioMorbusBechterew")}</FormLabel>
            <FormGroup>
              {[
                {label: "facharztFuerRheumatologieInPdfUebernehmen", value: RemicadeValues.facharztFuerRheumatologieInPdfUebernehmen},
                {label: "universitaetsklinikPoliklinik", value: RemicadeValues.universitaetsklinikPoliklinik},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeRemicadeValue({formData: {
                              [field.label]: event.target.checked,
                            }}),
                        )
                      }
                    />
                  }
                  label={t(`remicade.${field.label}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={
            !RemicadeValues.moderateBisSchwereColitisUlcerosa &&
            !RemicadeValues.schwerePlaquePsoriasis

          }
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={RemicadeValues.vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid}
                    onChange={(event) =>
                      dispatch(
                          onChangeRemicadeValue({formData: {
                            vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid: event.target.checked,
                          }}),
                      )
                    }
                  />
                }
                label={t("remicade.vorangegangeneTherapieWarUnzulaenglichAzathioprinMercaptopurinGlukokortikoid")}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.schwerePlaquePsoriasis}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("remicade.plaquePsoriasisBisherigeTherapie")}</FormLabel>
            <FormGroup>
              {[
                {label: "phototherapieUVBUVA", value: RemicadeValues.phototherapieUVBUVA},
                {label: "konventionelleTherapieMitCiclosporin", value: RemicadeValues.konventionelleTherapieMitCiclosporin},
                {label: "methotrexat", value: RemicadeValues.methotrexat},
                {label: "acitretin", value: RemicadeValues.acitretin},
                {label: "tnfAlphaBlockierendeSubstanz", value: RemicadeValues.tnfAlphaBlockierendeSubstanz},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) =>
                        dispatch(
                            onChangeRemicadeValue({formData: {
                              [field.label]: event.target.checked,
                            }}),
                        )
                      }
                    />
                  }
                  label={t(`remicade.${field.label}`)}
                />
              ))}

              <DrugPlanForm
                hidden={!RemicadeValues.schwerePlaquePsoriasis || !RemicadeValues.tnfAlphaBlockierendeSubstanz}

                label={t("remicade.tnfAlphaBlockierendeSubstanzName")}
                data-cy="tnfAlphaBlockierendeSubstanzName"
                onChange={(newValue) =>
                  dispatch(onChangeRemicadeValue({formData: {tnfAlphaBlockierendeSubstanzName: newValue}}))}
                value={RemicadeValues.tnfAlphaBlockierendeSubstanzName || ""}
              />


            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.psoriasisArthritis}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("remicade.psoriasisArthritis")}</FormLabel>
            <RadioGroup
              aria-labelledby="remicade-radio-buttons-group-psoriasisArthritis"
              name="remicade-radio-buttons-group-psoriasisArthritis"
              value={Object.keys(RemicadeValues).find((key) => RemicadeValues[key] === true) || ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const updatedValues = {
                  kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos: false,
                  therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos: false,
                  andereTherapieWarErfolglos: false,
                  [selectedValue]: true,
                };
                dispatch(onChangeRemicadeValue({formData: updatedValues}));
              }}
            >
              {[
                {label: "kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos", value: RemicadeValues.kombinationstherapieMitMethotrexatEtanerceptOderMethotrexatAdalimumabWarErfolglos},
                {label: "therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos", value: RemicadeValues.therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos},
                {label: "andereTherapieWarErfolglos", value: RemicadeValues.andereTherapieWarErfolglos},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  value={field.label}
                  control={<Radio checked={field.value} data-cy={field.label} />}
                  label={t(`remicade.${field.label}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}
          hidden={!RemicadeValues.psoriasisArthritis ||
          !RemicadeValues.therapieBestehendAusMind2DMARDsInklMethotrexatWarErfolglos} >
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("remicade.vorgeseheneTherapievariante")}</FormLabel>
            <RadioGroup
              aria-labelledby="remicade-radio-buttons-group-therapievariante"
              name="remicade-radio-buttons-group-therapievariante"
              value={Object.keys(RemicadeValues).find((key) => RemicadeValues[key] === true) || ""}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const updatedValues = {
                  kombinationstherapieMitMethotrexat: false,
                  kombinationstherapieMitAnderemMedikament: false,
                  monotherapie: false,
                  [selectedValue]: true,
                };
                dispatch(onChangeRemicadeValue({formData: updatedValues}));
              }}
            >
              {[
                {label: "kombinationstherapieMitMethotrexat", value: RemicadeValues.kombinationstherapieMitMethotrexat},
                {label: "kombinationstherapieMitAnderemMedikament", value: RemicadeValues.kombinationstherapieMitAnderemMedikament},
                {label: "monotherapie", value: RemicadeValues.monotherapie},
              ].map((field) => (
                <FormControlLabel
                  key={field.label}
                  value={field.label}
                  control={<Radio checked={field.value} data-cy={field.label} />}
                  label={t(`remicade.${field.label}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <DrugPlanForm
            hidden={!RemicadeValues.psoriasisArthritis || !RemicadeValues.kombinationstherapieMitAnderemMedikament
            }
            label={t("remicade.kombinationstherapieMitAnderemMedikamentName")}
            data-cy="kombinationstherapieMitAnderemMedikamentName"
            onChange={(newValue) =>
              dispatch(onChangeRemicadeValue({formData: {kombinationstherapieMitAnderemMedikamentName: newValue}}))}
            value={RemicadeValues.kombinationstherapieMitAnderemMedikamentName || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField

            hidden={!RemicadeValues.psoriasisArthritis ||
                  !RemicadeValues.andereTherapieWarErfolglos
            }

            style={{width: "100%"}}
            label={t("remicade.beschreibungVortherapie")}
            value={RemicadeValues.beschreibungVortherapie}
            variant="outlined"
            multiline
            onChange={(event) =>
              dispatch(
                  onChangeRemicadeValue({formData: {
                    beschreibungVortherapie: event.target.value,
                  }}),
              )
            }
          />
        </Grid>


        <Grid item xs={12}>
          <Box sx={{m: 3}} />
          <TextField
            hidden={RemicadeValues.andereIndikationSollBehandeltWerden}

            style={{width: "100%"}}
            label={t("remicade.bemerkungenZurTherapie")}
            value={RemicadeValues.bemerkungenZurTherapie}
            variant="outlined"
            multiline
            onChange={(event) =>
              dispatch(
                  onChangeRemicadeValue({formData: {
                    bemerkungenZurTherapie: event.target.value,
                  }}),
              )
            }
          />
        </Grid>
        <Grid item xs={12}
          hidden={!RemicadeValues.andereIndikationSollBehandeltWerden}
        >

          <Box sx={{m: 3}} />
          {[
            {label: "preTreatment", dataCy: "preTreatment", value: RemicadeValues.preTreatment},
            {label: "patientCondition", dataCy: "patientCondition", value: RemicadeValues.patientCondition},
            {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: RemicadeValues.reasonsAlternativeTherapiesFailed},
            {label: "prognosis", dataCy: "prognosis", value: RemicadeValues.prognosis},
          ].map((field, index) => (
            <React.Fragment key={field.label}>
              <TextField
                style={{width: "100%"}}
                label={t(`${field.label}`)}
                data-cy={field.dataCy}
                InputLabelProps={{shrink: true}}
                value={field.value}
                variant="outlined"
                maxRows={4}
                minRows={4}
                multiline
                onChange={(event) =>
                  dispatch(
                      onChangeRemicadeValue({formData: {
                        [field.label]: event.target.value,
                      }}),
                  )
                }
              />
              <Box sx={{m: 3}} />
            </React.Fragment>
          ))}
        </Grid>

        {variant > 0 && <Grid item xs={12}>
          <Typography
            data-cy="variant"

          >{t("remicade.variant")} {variant}</Typography>
        </Grid>}
      </Grid>

    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(RemicadeForm);
