import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,

  Checkbox,
} from "@mui/material";
import {setStudyById, removeStudy} from "pages/Wizard/wizardSlice";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";

import {
  onChangeTukysaValue,
  selectTukysaValues,

} from "./TukysaSlice";

const TukysaForm = (props) => {
  // usestate for variant, numeric value:
  const [variant, setVariant] = useState(0);

  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const TukysaValues = UseAppSelector(selectTukysaValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);


  useEffect(() => {
    console.debug("TukysaValues changed we check for Variant", TukysaValues);

    if (
      TukysaValues.chk_trastuzumab &&
      TukysaValues.chk_pertuzumab &&
      TukysaValues.chk_trastuzumabEmtansin &&
      TukysaValues.chk_incombination_trastuzumabCapecitabin
    ) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
    } else {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }

    if (
      TukysaValues.chk_trastuzumabDeruxtecan === true &&
      (
        TukysaValues.chk_trastuzumab === false ||
        TukysaValues.chk_pertuzumab === false ||
        TukysaValues.chk_trastuzumabEmtansin === false
      )
    ) {
      dispatch(setStudyById({studyId: "38568692"}));
    } else {
      dispatch(removeStudy({}));
    }
  }, [
    TukysaValues,
  ]);


  useEffect(() => {
    console.debug("TukysaValues changed we check for Variant", TukysaValues);
    // Variant 1
    if (TukysaValues.options_isher2) {
      dispatch( onChangeTukysaValue({formData: {"chk_trastuzumab": true}}));
      dispatch( onChangeTukysaValue({formData: {"chk_pertuzumab": true}}));
      dispatch( onChangeTukysaValue({formData: {"chk_trastuzumabEmtansin": true}}));
      dispatch( onChangeTukysaValue({formData: {"chk_incombination_trastuzumabCapecitabin": true}}));
    }
  }, [
    TukysaValues.options_isher2,
  ]);

  useEffect(() => {
    console.debug("Variant changed", TukysaValues);
    switch (variant) {
      default:
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        break;
    }
  }, [
    variant,
  ]);


  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item xs={12}>
        <FormControl>
          <FormLabel>{t("tukysa.options_isher2_header")}</FormLabel>
          <RadioGroup
            aria-labelledby="tukysa-radio-buttons-group-form-type"
            name="tukysa-radio-buttons-group-form-type"
            onChange={(event, value) => {
              const isher2 = value === "options_isher2";
              dispatch(onChangeTukysaValue({formData: {options_isher2: isher2, options_isother: !isher2}}));
            }}
          >
            {[
              {value: "options_isher2", checked: TukysaValues.options_isher2},
              {value: "options_isother", checked: TukysaValues.options_isother},
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio checked={option.checked} data-cy={option.value} />}
                label={t(`tukysa.${option.value}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid container item spacing={2} hidden={!TukysaValues.options_isher2}>
        <Grid item xs={12}>
          <Typography>{t("tukysa.chk_trastuzumab_group")}</Typography>
        </Grid>
        {[
          {key: "chk_trastuzumab", label: t("tukysa.chk_trastuzumab")},
          {key: "chk_pertuzumab", label: t("tukysa.chk_pertuzumab")},
          {key: "chk_trastuzumabEmtansin", label: t("tukysa.chk_trastuzumabEmtansin")},
          {key: "chk_trastuzumabDeruxtecan", label: t("tukysa.chk_trastuzumabDeruxtecan")},
          {key: "chk_other", label: t("tukysa.chk_other")},
        ].map((checkbox) => (
          <Grid item xs={12} key={checkbox.key}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={TukysaValues[checkbox.key]}
                    onChange={(event) =>
                      dispatch(
                          onChangeTukysaValue({
                            formData: {[checkbox.key]: event.target.checked},
                          }),
                      )
                    }
                    data-cy={checkbox.key}
                  />
                }
                label={checkbox.label}
              />
            </FormControl>
          </Grid>
        ))}

        <Grid item xs={12} hidden={!TukysaValues.chk_other}>
          <TextField
            label={t("tukysa.justification_chk_trastuzumab_group")}
            variant="outlined"
            style={{width: "100%"}}
            fullWidth
            data-cy="chk_other_txt"
            value={TukysaValues.chk_other_txt}
            onChange={(event) =>
              dispatch(
                  onChangeTukysaValue({
                    formData: {chk_other_txt: event.target.value},
                  }),
              )
            }
          />
        </Grid>
        <Box sx={{m: 1}} />
        <Grid item xs={12}>
          <Typography>{t("tukysa.chk_incombination_variants")}</Typography>
        </Grid>

        {[
          {key: "chk_incombination_trastuzumabCapecitabin", label: t("tukysa.chk_incombination_trastuzumabCapecitabin")},
          {key: "chk_incombination_other", label: t("tukysa.chk_incombination_other")},
        ].map((checkbox) => (
          <Grid item xs={12} key={checkbox.key}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={TukysaValues[checkbox.key]}
                    onChange={(event) =>
                      dispatch(
                          onChangeTukysaValue({
                            formData: {[checkbox.key]: event.target.checked},
                          }),
                      )
                    }
                    data-cy={checkbox.key}
                  />
                }
                label={checkbox.label}
              />
            </FormControl>
          </Grid>
        ))}

        <Grid item xs={12} hidden={!TukysaValues.chk_incombination_other}>
          <TextField
            label={t("tukysa.chk_incombination_other_txt")}
            variant="outlined"
            style={{width: "100%"}}
            fullWidth
            data-cy="chk_incombination_other_txt"
            value={TukysaValues.chk_incombination_other_txt}
            onChange={(event) =>
              dispatch(
                  onChangeTukysaValue({
                    formData: {chk_incombination_other_txt: event.target.value},
                  }),
              )
            }
          />
        </Grid>


      </Grid>


      <Grid item xs={12}
        hidden={
          !TukysaValues.options_isother
        }
      >
        <Stack direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("Tukysa.warning.offlabel")}</Typography>
        </Stack>
        <Stack hidden={ !(variant === 11)} direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("Tukysa.warning.otherindication")}</Typography>
        </Stack>
        <Box sx={{m: 3}} />
        {[
          {label: "preTreatment", dataCy: "preTreatment", value: TukysaValues.preTreatment},
          {label: "patientCondition", dataCy: "patientCondition", value: TukysaValues.patientCondition},
          {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: TukysaValues.reasonsAlternativeTherapiesFailed},
          {label: "prognosis", dataCy: "prognosis", value: TukysaValues.prognosis},
        ].map((field, index) => (
          <React.Fragment key={field.label}>
            <TextField
              style={{width: "100%"}}
              label={t(field.label)}
              data-cy={field.dataCy}
              InputLabelProps={{shrink: true}}
              value={field.value}
              variant="outlined"
              maxRows={4}
              minRows={4}
              multiline
              onChange={(event) =>
                dispatch(
                    onChangeTukysaValue({
                      [field.label]: event.target.value,
                    }),
                )
              }
            />
            <Box sx={{m: 3}} />
          </React.Fragment>
        ))}
      </Grid>

      {variant > 0 && <Grid item xs={12}>
        <Typography
          data-cy="variant"

        >{t("Tukysa.variant")} {variant}</Typography>
      </Grid>}
    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(TukysaForm);
