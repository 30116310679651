import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // Pen or not Pen
  "options_isPen",
  "options_isNotPen",

  // Indication
  "indication_schwerePlaquePsoriasis",
  "indication_psoriasisArthritis",
  "indication_other",

  // DLQI Liste
  "dlqi_smaller10",
  "dlqi_larger10",

  // Befall Liste
  "dlqi_pasi",
  "dlqi_bsa",
  "dlqi_pga",
  "dlqi_number",

  // Manifestationen
  "befallVonGesicht",
  "befallDerKopfhaut",
  "befallDerGenitalien",
  "befallDerHaenderFingerUndOderNaegel",
  "starkerJuckreiz",
  "hartnaeckigePlaques",

  // Vorhergehende Therapien (Plaque Psoriasis)
  "plaquePsoriasisPhototherapie",
  "plaquePsoriasisCiclosporin",
  "plaquePsoriasisMethotrexat",
  "plaquePsoriasisAcitretin",
  "plaquePsoriasisAndereBiologikumBiologika",
  "plaquePsoriasisKeineVortherapie",

  // zutreffenden Grund an für die Anwendung von TREMFYA
  "plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg",
  "plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen",
  "plaquePsoriasisKontraindikation",

  // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
  "plaquePsoriasisfacharztFuerDermatologieIn",
  "plaquePsoriasisuniversitaetsklinikPoliklinik",

  // Vortherapie
  "psoriasisArthritisBehandlungCSDMARD",
  "psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie",

  // Vorhergehende Therapien bitte ankreuzen Psoriasis Arthritis
  "psoriasisArthritisMethotrexat",
  "psoriasisArthritisLeflunomid",
  "psoriasisArthritisSulfasalazin",
  "psoriasisArthritisAndereBiologikumBiologika",
  "psoriasisArthritisKeine",

  // Grund an für die Anwendung von TREMFYA
  "psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg",
  "psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen",
  "psoriasisArthritisKontraindikation",

  // psoriasisArthriti Kombinationstherapie
  "psoriasisArthritisMonotherapie",
  "psoriasisArthritisKombinationstherapieMethotrexat",
  "psoriasisArthritisKombinationstherapieOther",
  "psoriasisArthritisKombinationstherapieOtherMedication",

  // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
  "psoriasisArthritisfacharztFuerDermatologieIn",
  "psoriasisArthritisuniversitaetsklinikPoliklinik",

  // Plaque Psoraisis
  "plaquePsoraisisErhaltungstherapieAlle8Wochen",
  "plaquePsoraisisAnpassungDesBehandlungsintervalls",
  "plaquePsoraisisBegruendung",

  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",
];

export interface TremfyaState {
  formData: {
    // Pen or not Pen
    options_isPen: boolean,
    options_isNotPen: boolean,

    // Indication
    indication_schwerePlaquePsoriasis: boolean,
    indication_psoriasisArthritis: boolean,
    indication_other: boolean

    // DLQI Liste
    dlqi_smaller10: boolean,
    dlqi_larger10: boolean,

    // Befall Liste
    dlqi_pasi: boolean,
    dlqi_bsa: boolean,
    dlqi_pga: boolean,
    dlqi_number: number,

    // Manifestationen
    befallVonGesicht: boolean,
    befallDerKopfhaut: boolean,
    befallDerGenitalien: boolean,
    befallDerHaenderFingerUndOderNaegel: boolean,
    starkerJuckreiz: boolean,
    hartnaeckigePlaques: boolean,

    // Vorhergehende Therapien (Plaque Psoriasis)
    plaquePsoriasisPhototherapie: boolean,
    plaquePsoriasisCiclosporin: boolean,
    plaquePsoriasisMethotrexat: boolean,
    plaquePsoriasisAcitretin: boolean,
    plaquePsoriasisAndereBiologikumBiologika: boolean,
    plaquePsoriasisKeineVortherapie: boolean,

    // zutreffenden Grund an für die Anwendung von TREMFYA
    plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg: boolean,
    plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen: boolean,
    plaquePsoriasisKontraindikation: string,

    // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
    plaquePsoriasisfacharztFuerDermatologieIn: boolean,
    plaquePsoriasisuniversitaetsklinikPoliklinik: boolean,

    // Vortherapie
    psoriasisArthritisBehandlungCSDMARD: boolean,
    psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie: boolean,

   // Vorhergehende Therapien bitte ankreuzen Psoriasis Arthritis
    psoriasisArthritisMethotrexat: boolean,
    psoriasisArthritisLeflunomid: boolean,
    psoriasisArthritisSulfasalazin: boolean,
    psoriasisArthritisAndereBiologikumBiologika: boolean,
    psoriasisArthritisKeine: boolean,

    //  Grund an für die Anwendung von TREMFYA
    psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg: boolean,
    psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen: boolean,
    psoriasisArthritisKontraindikation: string,

    // psoriasisArthriti Kombinationstherapie
    psoriasisArthritisMonotherapie: boolean,
    psoriasisArthritisKombinationstherapieMethotrexat: boolean,
    psoriasisArthritisKombinationstherapieOther: boolean,
    psoriasisArthritisKombinationstherapieOtherMedication: string,

    // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
    psoriasisArthritisfacharztFuerDermatologieIn: boolean,
    psoriasisArthritisuniversitaetsklinikPoliklinik: boolean,

    // Plaque Psoraisis
    plaquePsoraisisErhaltungstherapieAlle8Wochen: boolean,
    plaquePsoraisisAnpassungDesBehandlungsintervalls: boolean,
    plaquePsoraisisBegruendung: string,


    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,


  }
}

const initialState:TremfyaState = {
  formData: {
    // Pen or not Pen
    options_isPen: false,
    options_isNotPen: false,

    // Indication
    indication_schwerePlaquePsoriasis: false,
    indication_psoriasisArthritis: false,
    indication_other: false,

    // DLQI Liste
    dlqi_smaller10: false,
    dlqi_larger10: false,

    // Befall Liste
    dlqi_pasi: false,
    dlqi_bsa: false,
    dlqi_pga: false,
    dlqi_number: 0,

    // Manifestationen
    befallVonGesicht: false,
    befallDerKopfhaut: false,
    befallDerGenitalien: false,
    befallDerHaenderFingerUndOderNaegel: false,
    starkerJuckreiz: false,
    hartnaeckigePlaques: false,

    // Vorhergehende Therapien (Plaque Psoriasis)
    plaquePsoriasisPhototherapie: false,
    plaquePsoriasisCiclosporin: false,
    plaquePsoriasisMethotrexat: false,
    plaquePsoriasisAcitretin: false,
    plaquePsoriasisAndereBiologikumBiologika: false,
    plaquePsoriasisKeineVortherapie: false,

    // zutreffenden Grund an für die Anwendung von TREMFYA
    plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg: false,
    plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen: false,
    plaquePsoriasisKontraindikation: "",

    // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
    plaquePsoriasisfacharztFuerDermatologieIn: false,
    plaquePsoriasisuniversitaetsklinikPoliklinik: false,

    // Vortherapie
    psoriasisArthritisBehandlungCSDMARD: false,
    psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie: false,

    // Vorhergehende Therapien bitte ankreuzen Psoriasis Arthritis
    psoriasisArthritisMethotrexat: false,
    psoriasisArthritisLeflunomid: false,
    psoriasisArthritisSulfasalazin: false,
    psoriasisArthritisAndereBiologikumBiologika: false,
    psoriasisArthritisKeine: false,

    // Grund an für die Anwendung von TREMFYA
    psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg: false,
    psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen: false,
    psoriasisArthritisKontraindikation: "",

    // psoriasisArthriti Kombinationstherapie
    psoriasisArthritisMonotherapie: false,
    psoriasisArthritisKombinationstherapieMethotrexat: false,
    psoriasisArthritisKombinationstherapieOther: false,
    psoriasisArthritisKombinationstherapieOtherMedication: "",

    // Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie
    psoriasisArthritisfacharztFuerDermatologieIn: false,
    psoriasisArthritisuniversitaetsklinikPoliklinik: false,

    // Plaque Psoraisis
    plaquePsoraisisErhaltungstherapieAlle8Wochen: false,
    plaquePsoraisisAnpassungDesBehandlungsintervalls: false,
    plaquePsoraisisBegruendung: "",

    // default stuff
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const TremfyaFormSlice = createSlice({
  name: "Tremfya",
  initialState,
  reducers: {
    onClearTremfyaState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedTremfyaEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedTremfyaEditData", payload);
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
        state.formData.plaquePsoriasisKontraindikation =
          payload.formData?.plaquePsoriasisKontraindikation;
        state.formData.psoriasisArthritisKontraindikation =
          payload.formData?.psoriasisArthritisKontraindikation;
        state.formData.plaquePsoraisisBegruendung =
          payload.formData?.plaquePsoraisisBegruendung;
        state.formData.psoriasisArthritisKombinationstherapieOtherMedication =
          payload.formData?.psoriasisArthritisKombinationstherapieOtherMedication;
      }
    },
    onChangeTremfyaValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeTremfyaValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      if (payload.plaquePsoriasisKontraindikation || payload.plaquePsoriasisKontraindikation === "") state.formData.plaquePsoriasisKontraindikation = payload.plaquePsoriasisKontraindikation;
      if (payload.psoriasisArthritisKontraindikation || payload.psoriasisArthritisKontraindikation === "") state.formData.psoriasisArthritisKontraindikation = payload.psoriasisArthritisKontraindikation;
      if (payload.plaquePsoraisisBegruendung || payload.plaquePsoraisisBegruendung === "") state.formData.plaquePsoraisisBegruendung = payload.plaquePsoraisisBegruendung;
      if (payload.psoriasisArthritisKombinationstherapieOtherMedication || payload.psoriasisArthritisKombinationstherapieOtherMedication === "") state.formData.psoriasisArthritisKombinationstherapieOtherMedication = payload.psoriasisArthritisKombinationstherapieOtherMedication;

      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedTremfyaEditData,
  onClearTremfyaState,
  onChangeTremfyaValue,
  onAddMedication,
  onRemoveMedication,
} = TremfyaFormSlice.actions;

export default TremfyaFormSlice.reducer;
export const selectTremfyaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.tremfyaForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("updatestate", JSON.stringify(data));
  if (!data) return;

  // Update specific options only if they are provided in data
  if ("options_isPen" in data) {
    state.formData.options_isPen = !!data.options_isPen;
    !isEdit && resetBelow(fieldNames, "options_isPen", data, state);
  }
  if ("options_isNotPen" in data) {
    state.formData.options_isNotPen = !!data.options_isNotPen && !data.options_isPen;
    !isEdit && resetBelow(fieldNames, "options_isNotPen", data, state);
  }

  // List of possible indications
  const indications = [
    "indication_schwerePlaquePsoriasis",
    "indication_psoriasisArthritis",
    "indication_other",
  ];
  // Loop through each indication and update the state only if present in data
  indications.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible DLQI options
  const dlqiOptions = [
    "dlqi_smaller10",
    "dlqi_larger10",
  ];
  // Update each DLQI option only if present in data
  dlqiOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Befall options
  const befallOptions = [
    "dlqi_pasi",
    "dlqi_bsa",
    "dlqi_pga",
    "dlqi_number",
  ];
  // Update each Befall option only if present in data
  befallOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Manifestationen options
  const manifestationenOptions = [
    "befallVonGesicht",
    "befallDerKopfhaut",
    "befallDerGenitalien",
    "befallDerHaenderFingerUndOderNaegel",
    "starkerJuckreiz",
    "hartnaeckigePlaques",
  ];
  // Update each Manifestationen option only if present in data
  manifestationenOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Vorhergehende Therapien (Plaque Psoriasis) options
  const plaquePsoriasisOptions = [
    "plaquePsoriasisPhototherapie",
    "plaquePsoriasisCiclosporin",
    "plaquePsoriasisMethotrexat",
    "plaquePsoriasisAcitretin",
    "plaquePsoriasisAndereBiologikumBiologika",
    "plaquePsoriasisKeineVortherapie",
  ];
  // Update each Vorhergehende Therapien (Plaque Psoriasis) option only if present in data
  plaquePsoriasisOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible zutreffenden Grund an für die Anwendung von TREMFYA options
  const tremfyaOptions = [
    "plaquePsoriasisDieBisherigeBehandlungZeigteKeinenErfolg",
    "plaquePsoriasisDieBisherigeBehandlungWurdeNichtVertragen",
    "plaquePsoriasisKontraindikation",
  ];
  // Update each zutreffenden Grund an für die Anwendung von TREMFYA option only if present in data
  tremfyaOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie options
  const limitatioOptions = [
    "plaquePsoriasisfacharztFuerDermatologieIn",
    "plaquePsoriasisuniversitaetsklinikPoliklinik",
  ];
  // Update each Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie option only if present in data
  limitatioOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Vortherapie options
  const vortherapieOptions = [
    "psoriasisArthritisBehandlungCSDMARD",
    "psoriasisArthritisBehandlungErwachsenerPatientenMitPsoriasisArthritisOhneVorgaengigeTherapie",
  ];
  // Update each Vortherapie option only if present in data
  vortherapieOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Vorhergehende Therapien bitte ankreuzen Psoriasis Arthritis options
  const psoriasisArthritisOptions = [
    "psoriasisArthritisMethotrexat",
    "psoriasisArthritisLeflunomid",
    "psoriasisArthritisSulfasalazin",
    "psoriasisArthritisAndereBiologikumBiologika",
    "psoriasisArthritisKeine",
  ];
  // Update each Vorhergehende Therapien bitte ankreuzen Psoriasis Arthritis option only if present in data
  psoriasisArthritisOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Grund an für die Anwendung von TREMFYA options
  const grundOptions = [
    "psoriasisArthritiDieBisherigeBehandlungZeigteKeinenUnzureichendenTherapeutischenErfolg",
    "psoriasisArthritiDieBisherigeBehandlungWurdeNichtVertragen",
    "psoriasisArthritisKontraindikation",
  ];
  // Update each Grund an für die Anwendung von TREMFYA option only if present in data
  grundOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible psoriasisArthriti Kombinationstherapie options
  const kombinationstherapieOptions = [
    "psoriasisArthritisMonotherapie",
    "psoriasisArthritisKombinationstherapieMethotrexat",
    "psoriasisArthritisKombinationstherapieOther",
    "psoriasisArthritisKombinationstherapieOtherMedication",
  ];
  // Update each psoriasisArthriti Kombinationstherapie option only if present in data
  kombinationstherapieOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie options
  const limitatioOptions2 = [
    "psoriasisArthritisfacharztFuerDermatologieIn",
    "psoriasisArthritisuniversitaetsklinikPoliklinik",
  ];
  // Update each Limitatio erfordert die Behandlung durch einen Facharzt der Dermatologie option only if present in data
  limitatioOptions2.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible Plaque Psoraisis options
  const plaquePsoraisisOptions = [
    "plaquePsoraisisErhaltungstherapieAlle8Wochen",
    "plaquePsoraisisAnpassungDesBehandlungsintervalls",
    "plaquePsoraisisBegruendung",
  ];
  // Update each Plaque Psoraisis option only if present in data
  plaquePsoraisisOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key];
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  if (isEdit) {
    resetBelow(fieldNames, "", data, state);
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
